import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React from 'react';

import {Box} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import {formatDate} from 'utils/date/formatDate';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import IconAgency from './assets/agency.png';
import IconAirportPickup from './assets/airport_pickup.png';
import IconAirportStation from './assets/airport_station.png';
import IconCentralStationPickup from './assets/central_station_pickup.png';
import IconEcompetence from './assets/ecompetence_icon.png';
import IconLateNight from './assets/late_night.png';
import IconLicensee from './assets/licensee.png';
import IconPartner from './assets/partner.png';
import IconPickupStation from './assets/pickupstation.png';
import IconRemoteStation from './assets/remote_station.png';
import styles from './styles/stationList.module.scss';
import {IStationWithDistance} from './types';

const StationListElement = ({
  station,
  onClick,
  hoverHandler,
  isActive = false,
}: {
  hoverHandler: (station: Station) => void;
  station: IStationWithDistance;
  onClick: (station: IStationWithDistance) => void;
  isActive?: boolean;
}) => {
  const {t} = useTranslation(['bpr', 'common']);
  const date = new Date();
  let stationOpeningDate = new Date();
  let patch = false;
  let stationIsOpen = true;

  if (
    station.airportStation ||
    station.airportPickupService ||
    station.partner ||
    station.trainPickupService ||
    station.lateNightService ||
    station.ecompetence ||
    station.agency ||
    station.licensee ||
    station.pickupStation ||
    station.remoteStation
  ) {
    patch = true;
  }

  if (station.openingDate) {
    stationOpeningDate = new Date(station.openingDate);

    if (date.getTime() < stationOpeningDate.getTime()) {
      stationIsOpen = false;
    }
  }

  return (
    <Box
      component="li"
      className={`${styles.element} ${isActive ? styles['_active'] : ''}`}
      onMouseEnter={() => hoverHandler(station)}
      onClick={() => onClick(station)}
      sx={station.partner ? {fontStyle: 'italic'} : {}}
    >
      <span>{station.description}</span>
      <span>{station.distance && <> ({station.distance.toFixed(2)} km)</>}</span>
      {patch && (
        <Box className={styles.patch}>
          {station.airportPickupService && (
            <Image src={IconAirportPickup} alt={t('airportPickup')} />
          )}
          {station.partner && <Image src={IconPartner} alt={t('starcarPartner', {ns: 'common'})} />}
          {station.airportStation && <Image src={IconAirportStation} alt={t('airportStation')} />}
          {station.trainPickupService && (
            <Image src={IconCentralStationPickup} alt={t('centralStationPickup')} />
          )}
          {station.pickupStation && <Image src={IconPickupStation} alt={t('pickupStation')} />}
          {station.remoteStation && <Image src={IconRemoteStation} alt={t('remoteStation')} />}
          {station.lateNightService && <Image src={IconLateNight} alt={t('lateNightStation')} />}
          {station.ecompetence && <Image src={IconEcompetence} alt={t('eCompetenceService')} />}
          {station.agency && <Image src={IconAgency} alt={t('agency')} />}
          {station.licensee && (
            <Image src={IconLicensee} alt={t('licenseHolder', {ns: 'common'})} />
          )}
        </Box>
      )}
      {!stationIsOpen && (
        <Box className={styles.openingDate}>
          <>Ab {formatDate(stationOpeningDate)}</>
        </Box>
      )}
    </Box>
  );
};

export default withErrorBoundary(StationListElement, 'StationListElement');
