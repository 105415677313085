import isEqual from 'lodash/isEqual';
import {useTranslation} from 'next-i18next';
import React from 'react';

import {Typography} from '@mui/material';

import {formatTime} from 'utils/date/formatDate';

import {Box} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './styles/stationCard.module.scss';

const StationInformation = ({station}) => {
  const {t} = useTranslation(['bpr', 'common']);
  if (!station) return null;

  return (
    <>
      <Typography component="p" variant="h4" className={styles.headline}>
        {station.remoteStation
          ? t('meeting_point', {ns: 'common'})
          : station.isRelatedStation
          ? t('related_station', {ns: 'common'})
          : t('address', {ns: 'common'})}
      </Typography>
      <Box component="address" className={styles.address}>
        {station.isRelatedStation && (
          <>
            <span className={styles.relatedStationDescription}>{station.description}</span>
            <br />
          </>
        )}
        {station.subName && (
          <>
            <span>{station.subName}</span>
            <br />
          </>
        )}
        {station.street && station.zip && station.city && (
          <>
            <span>{station.street}</span>
            <br />
            {station.zip} {station.city}
          </>
        )}
        {station.phone && (
          <>
            <div>{`${t('telefon', {ns: 'common'})}: ${station.phone}`}</div>
          </>
        )}
        {station.fax && (
          <>
            <div>{`${t('fax', {ns: 'common'})}: ${station.fax}`}</div>
          </>
        )}
        {station.email && (
          <>
            <div>
              <a href={`mailto:${station.email}`}>{station.email}</a>
            </div>
          </>
        )}
        <br />
      </Box>
      {!station.isRelatedStation && (
        <>
          <Typography component="p" variant="h4" className={styles.headline} mt={1}>
            {t('businessHours', {ns: 'common'})}
          </Typography>
          <Box className={styles.address}>
            {station.businessHours.all[1].closed === false ? (
              <span>
                <span>{`${t('moFr', {ns: 'common'})}: ${formatTime(
                  station.businessHours.all[1].from,
                )} - `}</span>
                <span>{formatTime(station.businessHours.all[1].to)}</span>
              </span>
            ) : (
              <span>
                {t('moFr', {ns: 'common'})}: {t('closed', {ns: 'common'})}
              </span>
            )}
            <br />
            {station.businessHours.all[6].closed === false ? (
              <span>
                <span>{`${t('sa', {ns: 'common'})}: ${formatTime(
                  station.businessHours.all[6].from,
                )} - `}</span>
                <span>{formatTime(station.businessHours.all[6].to)}</span>
              </span>
            ) : (
              <span>
                {t('sa', {ns: 'common'})}: {t('closed', {ns: 'common'})}
              </span>
            )}
            <br />
            {station.businessHours.all[0].closed === false ? (
              <span>
                <span>{`${t('su', {ns: 'common'})}: ${formatTime(
                  station.businessHours.all[0].from,
                )} - `}</span>
                <span>{formatTime(station.businessHours.all[0].to)}</span>
              </span>
            ) : (
              <span>{`${t('su', {ns: 'common'})}: ${t('closed', {ns: 'common'})}`}</span>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(withErrorBoundary(StationInformation, 'StationInformation'), isEqual);
