import loginImage from 'assets/login.jpeg';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React, {useState} from 'react';

import {Typography} from '@mui/material';

import {Grid, Modal} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import LoginForm from 'components/form-components/LoginForm';
import DefaultWrapper from 'components/layout/DefaultWrapper';

import loginButtonStyles from '../Menu.module.scss';
import styles from './LoginModal.module.scss';

const LoginModal = () => {
  const {t} = useTranslation('loginModal');
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Modal
      modalOpen={modalOpen}
      onModalOpen={setModalOpen}
      onModalClose={setModalOpen}
      // open={false}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customTrigger={(props: any) => (
        <a id="navbarLoginLink" {...props} className={loginButtonStyles.login}>
          <span>{t('login')}</span>
        </a>
      )}
    >
      <Grid container>
        <Grid xs={0} sm={6} sx={{position: 'relative'}}>
          <Image src={loginImage} alt="" layout="fill" objectFit="cover" objectPosition="center" />
        </Grid>
        <Grid xs={12} sm={6}>
          <LoginForm
            variant="loginModal"
            loginButtonPosition="flex-start"
            onLoginSuccess={() => setModalOpen(false)}
            closeModalHandler={() => setModalOpen(false)}
            HeadComponents={
              <>
                <div className={styles.headline}>{t('login')}</div>
              </>
            }
          />
          <DefaultWrapper bgcolor="light-grey" variant="paddingAround">
            <div className={styles.secondaryHeadline} style={{marginBottom: '10px'}}>
              {t('loginRequested')}?
            </div>
            <div>
              {t('requestLoginWithNextReservation')}
              <span className={styles.secondaryHeadline}> {t('discount')}. </span>
              <Typography variant="small1">({t('validForDiscountableRates')})</Typography>
            </div>
          </DefaultWrapper>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withErrorBoundary(LoginModal, 'LoginModal');
