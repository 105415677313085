import React from 'react';

import {Tabs} from '@mui/material';
import {Tab as MuiTab} from '@mui/material';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './Footer.module.scss';

const Tab = props => (
  <div className={styles.tabWrapper}>
    <MuiTab {...props}></MuiTab>
  </div>
);
Tab.muiName = 'Tab';

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const FooterTabs = ({footerMenuGroups, value, setValue}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs
      id="footer-tabs"
      value={value}
      onChange={handleChange}
      textColor="inherit"
      variant="fullWidth"
      aria-label="full width tabs example"
      sx={{
        width: '100%',
        minHeight: 'auto',
        '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
        },
        '& .Mui-selected span': {
          '&::after': {
            left: 0,
            width: '100%',
            background: 'none repeat scroll 0 0 transparent',
            bottom: '-5px',
            content: "''",
            display: 'block',
            height: '3px',
            position: 'absolute',
            backgroundColor: '#000',
            transition: 'width 0.2s ease 0s, left 0.2s ease 0s',
          },
        },
        '& button': {
          padding: 0,
          alignItems: 'start',
          display: 'inline-block',
          fontSize: '0.8rem',
          minHeight: '25px',
          maxWidth: 'fit-content',
          minWidth: 'auto',
          opacity: '1',
        },
        '& MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {footerMenuGroups.map((group, index) => {
        return (
          <Tab
            key={`tab-${group.id}-${group.title}`}
            label={<span className={styles.tabLabel}>{group.title}</span>}
            {...a11yProps(index)}
            disableRipple
          ></Tab>
        );
      })}
    </Tabs>
  );
};

export default withErrorBoundary(FooterTabs, 'FooterTabs');
