import FormHelperText from '@mui/material/FormHelperText';

const FormErrors = ({errors = []}: {errors: string[]}) => {
  // console.log(errors.length, errors);
  if (!errors || !errors.length) {
    return null;
  }
  return (
    <>
      {errors.map(error => (
        <FormHelperText key={error} error>
          {error}
        </FormHelperText>
      ))}
    </>
  );
};

export default FormErrors;
