import {getLocalePrefix} from 'hooks/useLocalePrefix';
import {useTranslation} from 'next-i18next';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Popover, Typography} from '@mui/material';

import {Box, Icon, Title} from 'components/basic-components';
import {TTranslatedSlugs} from 'components/layout/Navbar/types';

import styles from './index.module.scss';

export interface Props {
  translatedSlugs: TTranslatedSlugs | null;
}
const LanguageSwitch: FC<Props> = ({translatedSlugs}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [t] = useTranslation('common');
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language selection' : undefined;
  const router = useRouter();
  const {asPath, locales, locale, defaultLocale} = router;
  const translatedSlugsObject = translatedSlugs?.reduce(
    (acc, slug) => ({...acc, [slug.locale]: slug}),
    {},
  );
  if (locales?.length <= 1) return null;
  // href needs to be the link without prepended locale and "as" needs to be link with prepended locale for default locale, so prefetching works correctly
  // https://lifesaver.codes/answer/getting-strange-404-before-page-loads
  const languageLinks = locales
    .filter(locale => locale !== 'default')
    .map(locale => (
      <li
        onClick={handleClose}
        className={styles.menuLinks}
        key={'langaugeSwitch-' + locale}
        id={'langaugeSwitch-' + locale}
      >
        <Link
          passHref
          as={
            translatedSlugsObject?.[locale]?.slug
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}${
                  translatedSlugsObject?.[locale]?.slug
                }`
              : translatedSlugs?.length === locales.length
              ? getLocalePrefix({locale, defaultLocale, slash: 'back-front'})
              : `${getLocalePrefix({locale, defaultLocale, slash: 'front'})}${asPath}`
          }
          href={
            translatedSlugsObject?.[locale]?.slug
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}${
                  translatedSlugsObject?.[locale]?.slug
                }`
              : translatedSlugs?.length === locales.length
              ? `/${getLocalePrefix({locale, defaultLocale, slash: 'back'})}`
              : `${`/${locale}${asPath}`}`
          }
          locale={false}
        >
          <div className={styles.menuContent}>
            <Typography>
              {locale === 'en' ? 'English' : locale === 'de' ? 'Deutsch' : null}
            </Typography>
          </div>
        </Link>
      </li>
    ));
  return (
    <>
      <div id="navbarLanguageSwitch" onClick={handleClick} className={styles.menu}>
        <Icon baseClassName="fa-solid" className="fa-globe" />
        <Typography sx={{fontWeight: '700', marginTop: '1px'}}>
          {locale === 'en' ? 'EN' : locale === 'de' ? 'DE' : null}
        </Typography>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            border: 'none',
          },
        }}
      >
        <OutsideClickHandler onOutsideClick={handleClose}>
          <Box
            sx={{
              position: 'relative',
              mt: '10px',
              '&::before': {
                backgroundColor: '#f3f3f3',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 12,
                height: 12,
                top: -6,
                transform: 'rotate(45deg)',
                left: {xs: 'calc(50% + 20px)', md: '50%'},
              },
            }}
            className={styles.popoverContent}
          >
            <Title
              variant="h5"
              sx={{
                marginBottom: '10px',
                marginLeft: '30px',
                marginRight: '30px',
                marginTop: '10px',
              }}
            >
              {t('selectLanguage')}
            </Title>
            {languageLinks}
          </Box>
        </OutsideClickHandler>
      </Popover>
    </>
  );
};

export default LanguageSwitch;
