import {Locale} from 'date-fns';
import {format} from 'date-fns-tz';
import {de, enUS} from 'date-fns/locale';

export const formatDateString = (date: Date | string) => {
  if (!date) {
    return '';
  }
  date = new Date(date);
  const res = `${date.getFullYear()}-${prependZero(date.getMonth() + 1)}-${prependZero(
    date.getDate(),
  )} ${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
  return res;
};

const prependZero = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const formatDate = dateString => {
  const res = new Date(dateString).toLocaleDateString('de', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return res;
};

export const formatTime = dateString => {
  return new Date(dateString).toLocaleTimeString('de', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

//return DateString in following Format: day/month
//Example: 03.06.
export const formatDayMonthDate = dateString => {
  return new Date(dateString).toLocaleDateString('de', {
    day: '2-digit',
    month: '2-digit',
  });
};

// returns DateString in following Format: weekday/day/month/year/hour/minute
// Example: Do 05.05.2023 16:00
export const formatFullDate = (dateString, locale = 'de') => {
  let localeObj: Locale;
  switch (locale) {
    case 'en':
      localeObj = enUS;
      break;
    case 'de':
    default:
      localeObj = de;
      break;
  }
  return format(new Date(dateString), 'eeeeee dd.MM.yyyy HH:mm', {
    locale: localeObj,
  });
};

export const formatWithPattern = (dateString, pattern = 'dd.MM.yyyy HH:mm') => {
  return format(new Date(dateString), pattern, {
    locale: de,
  });
};

//returns DateString in following Format: day/month/hour/minute
//Example: 31.01. 08:00
export const formatMonthYearDate = dateString => {
  return format(new Date(dateString), 'dd.MM. HH:mm', {locale: de});
};
