import {useTranslation} from 'next-i18next';
import {Control, Controller} from 'react-hook-form';

import {TextFieldProps} from '@mui/material';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {Icon, InputAdornment, TextField} from 'components/basic-components';
import FormErrors from 'components/basic-components/FormErrors';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './Styles/formInputStation.module.scss';

const FormInputStation = ({
  control,
  errors,
  onFocus,
  onBlur,
  onClear,
  disabled,
  inputRef,
  color = 'secondary',
}: {
  control: Control<any, any>;
  errors: TMergedErrors;
  onFocus: any;
  disabled?: boolean;
  onBlur?: Function;
  onClear?: Function;
  color?: TextFieldProps['color'];
  inputRef?: any;
}) => {
  const [t] = useTranslation('bpr');

  return (
    <>
      <Controller
        control={control}
        name="stationTextField"
        render={({field}) => {
          return (
            <TextField
              inputRef={inputRef}
              color={color}
              disabled={disabled}
              autoComplete="off"
              className={errors?.station ? 'error' : ''}
              fullWidth
              placeholder={t('enterCityZip')}
              label={
                <>
                  <Icon className="fa-map-marker-alt" />
                  {t('rentalStation')}
                </>
              }
              {...field}
              onBlur={() => {
                onBlur();
                field.onBlur();
              }}
              onFocus={onFocus}
              InputProps={{
                endAdornment:
                  field?.value?.length && onClear ? (
                    <InputAdornment className={styles.clearIcon} onClick={onClear} position="start">
                      <Icon title={t('clearInput')} baseClassName="fa-solid" className="fa-times" />
                    </InputAdornment>
                  ) : null,
              }}
            />
          );
        }}
      />
      <FormErrors errors={errors.station} />
    </>
  );
};

export default withErrorBoundary(FormInputStation, 'FormInputStation');
