import React from 'react';
import PropTypes from 'prop-types';
import styles from './stylesheets/datepicker.module.scss';

export default class MonthDropdownOptions extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    monthNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

  isSelectedMonth = i => this.props.month === i;

  renderOptions = () => {
    return this.props.monthNames.map((month, i) => (
      <div
        className={
          this.isSelectedMonth(i)
            ? `${styles['__month-option']} ${styles['__month-option--selected_month']}`
            : styles['__month-option']
        }
        key={month}
        onClick={this.onChange.bind(this, i)}
        aria-selected={this.isSelectedMonth(i) ? 'true' : undefined}
      >
        {this.isSelectedMonth(i) ? (
          <span className={styles['__month-option--selected']}>✓</span>
        ) : (
          ''
        )}
        {month}
      </div>
    ));
  };

  onChange = month => this.props.onChange(month);

  handleClickOutside = () => this.props.onCancel();

  render() {
    return <div className={styles['__month-dropdown']}>{this.renderOptions()}</div>;
  }
}
