import {FooterMenuGroup, NavPropsContext} from 'context/navPropsContext';
import React, {useContext} from 'react';

import DefaultWrapper from '../DefaultWrapper/DefaultWrapper';
import FooterTabPanel from './FooterTabPanel';
import FooterTabs from './FooterTabs';
import { withErrorBoundary } from 'components/error/ErrorBoundary';

export const Footer = () => {
  const navProps = useContext(NavPropsContext);
  const footerMenuGroups: FooterMenuGroup[] = navProps?.footerMenuGroups || [];
  const [value, setValue] = React.useState(0);

  return (
    <DefaultWrapper bgcolor="yellow" variant="paddingAround">
      <FooterTabs footerMenuGroups={footerMenuGroups} value={value} setValue={setValue} />
      <FooterTabPanel footerMenuGroups={footerMenuGroups} value={value} />
    </DefaultWrapper>
  );
};

export default withErrorBoundary(Footer, 'Footer');
