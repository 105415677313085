import {UseMutationResult} from 'react-query';

import {
  Estimation,
  Group,
  PostApiV1PostEstimateReservation400,
  Rate,
  ReservationEstimationDto,
} from 'lib/api/backend.schemas';
import {Reservation} from 'lib/api/backend.schemas';
import {ErrorType} from 'lib/api/mutator/custom-instance';

interface RateWithCategory extends Rate {
  item_category: string;
}
type GTMAffiliationType =
  | 'oneway'
  | 'kostenlos_mieten'
  | 'best_price'
  | 'sale'
  | 'tarife'
  | 'longterm';
interface GTMReservationEvent {
  event: string;
  affiliation: GTMAffiliationType;
  value: number;
  station?: string;
  user_type?: TUserType;
  category?: string;
  kilometer?: number;
  ecommerce: GTMEcommerceItem[] | {items: GTMEcommerceItem[]};
}
type TUserType = 'guest' | 'register_account' | 'existing_account';
interface GTMEcommerceItem {
  item_id: string;
  item_name: string;
  item_category: string;
  item_category2?: string;
  item_variant?: string;
  price: number | string;
  quantity: number;
}
type EstimationQueryType = UseMutationResult<
  Estimation,
  ErrorType<void | PostApiV1PostEstimateReservation400>,
  {
    reservation: string;
    data: ReservationEstimationDto;
  },
  unknown
>;
export const getUserTypeFromReservation = (reservation: Reservation): TUserType => {
  if (reservation?.createLogin) {
    return 'register_account';
  } else if (reservation?.customer?.id) {
    return 'existing_account';
  } else {
    return 'guest';
  }
};
export const getSubratesFromReservation = (reservation: Reservation) => {
  const {subrates} = reservation;

  const activeSubrates = Object.entries(subrates)
    .map(([key, value]) => value.map(item => ({...item, item_category: key})))
    .reduceRight((acc, item) => [...acc, ...item], [])
    .filter(subrate => subrate.count > 0);
  return activeSubrates as RateWithCategory[];
};

export const getItemsArrayForGTMFromSubrates = (
  subrates: RateWithCategory[],
): GTMEcommerceItem[] => {
  const subratesForGTM = subrates.map(({item_category, ...subrate}: RateWithCategory) => {
    return {
      item_id: subrate.name,
      item_name: subrate.description,
      item_category,
      price: subrate.price,
      quantity: 1,
    };
  });
  return subratesForGTM;
};
export const getGroupItemFromReservation = (reservation: Reservation) => {
  const {group} = reservation;
  return {
    item_id: group?.id,
    item_name: reservation?.mainRate?.name || group?.description,
    item_category: group?.category, // optional
    item_category2: group?.type, //optional
    item_variant: reservation?.mainRate?.name || undefined, // optional
    price: reservation?.mainRate?.price ? reservation?.mainRate?.price : (0 as number),
    quantity: 1,
  };
};
export const getOnewayEcommerceItems = ({
  rate,
  groupId,
  category,
}: {
  rate: Rate;
  groupId: string;
  category: string;
}) => {
  return {
    item_id: groupId,
    item_name: rate.name,
    item_category: category, // optional
    item_category2: null, //optional
    item_variant: rate.name || undefined, // optional
    price: rate.price,
    quantity: 1,
  };
};
export const getItemsArrayForGTMReservation = (reservation: Reservation) => [
  getGroupItemFromReservation(reservation),
  ...getItemsArrayForGTMFromSubrates(getSubratesFromReservation(reservation)),
];

export const beginCheckoutDatalayer = (
  reservation: Reservation,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data, isLoading, isSuccess, isError} = estimationMethods;
  return {
    event: 'begin_checkout',
    affiliation: getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
    user_type: getUserTypeFromReservation(reservation),
  };
};

export const addToCartDatalayer = (
  reservation: Reservation,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data, isLoading, isSuccess, isError} = estimationMethods;
  return {
    event: 'add_to_cart',
    station: reservation?.station?.id,
    category: reservation?.category,
    kilometer: reservation?.distance,
    affiliation: getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
  };
};
export const addShippingInfoDatalayer = (
  reservation: Reservation,
  user_type: TUserType,
  estimationMethods?: EstimationQueryType,
): GTMReservationEvent => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  const {data, isLoading, isSuccess, isError} = estimationMethods;
  return {
    event: 'add_shipping_info',
    station: reservation?.station?.id,
    category: reservation?.category,
    kilometer: reservation?.distance,
    user_type,
    affiliation: getReservationType(reservation),
    ecommerce: {items: subrateItems},
    value: data?.total,
  };
};
export const purchaseDatalayer = (reservation: Reservation) => {
  const subrateItems = getItemsArrayForGTMReservation(reservation);
  return {
    event: 'purchase',
    value: reservation?.finalTotal,
    user_type: getUserTypeFromReservation(reservation),
    affiliation: getReservationType(reservation),
    ecommerce: {
      partner_id: reservation?.partnerId || 'no-id',
      transaction_id: reservation.id,
      tax: reservation?.finalEstimation?.total - reservation?.finalEstimation?.netTotal,
      shipping: 0,
      currency: 'EUR',
      payment_type: reservation.customer?.payment?.type,
      reservierungsnummer: reservation?.bookingNumber,
      kundenkategorie:
        reservation?.customer?.type === 'Login'
          ? reservation?.customer?.isBusinessClient
            ? 'company'
            : 'private'
          : reservation?.customer?.type?.replace('customer.type.', ''),
      gruppe: reservation?.group?.id,
      rate: reservation?.mainRate?.name,
      abholstation: reservation?.station?.id,
      kilometer: reservation?.distance,
      items: subrateItems,
    },
  };
};
interface IGroupWithPrice extends Group {
  price?: boolean | string;
}
export const bestPriceGroupCardFlip = (
  group: IGroupWithPrice,
  affiliation: GTMAffiliationType,
  rateName?: string,
  preferredVehicle?: string,
): GTMReservationEvent => {
  if (group?.longterm) affiliation = 'longterm';
  // item_name = gruppen ID
  return {
    event: 'view_item',
    affiliation: affiliation, // oneway, kostenlos_mieten, best_price, sale
    value: 0,
    ecommerce: {
      items: [
        // Fahrzeug + Zusatzbuchungen
        {
          item_id: group?.id,
          item_name: preferredVehicle || group?.description,
          item_category: group?.category, // optional
          item_variant: rateName || undefined,
          item_category2: group?.type, // optional
          price: group?.price ? (group?.price as string) : (0 as number),
          quantity: 1,
        },
      ],
    },
  };
};

export const getReservationType = (reservation: Reservation): GTMAffiliationType => {
  if (reservation.sale) return 'sale';
  if (reservation.oneWay) return 'oneway';
  if (reservation.rentForFree) return 'kostenlos_mieten';
  return 'best_price';
};
