import { FieldErrorsImpl } from "react-hook-form/dist/types";

export type TMergedErrors = { [key: string]: string[]; }
const mergeErrors = (formErrors: FieldErrorsImpl<{ [key: string]: string; }>, requestErrors: { [key: string]: string[]; }) => {
    const formErrorKeys = Object.keys(formErrors);
    const requestErrorKeys = Object.keys(requestErrors);
    const allKeys = [...formErrorKeys, ...requestErrorKeys];
    const mergedErrors =  allKeys.reduce((acc, key) => {
        if(!acc[key]) {
            acc[key] = [];
        }
        if(formErrors[key] && formErrors[key]?.message) {
            acc[key].push(formErrors[key]?.message)
        } else if(requestErrors[key]) {
            acc[key] = [...requestErrors[key]]
        }
        return acc
    }, {} as { [key: string]: string[]; })
    return mergedErrors as TMergedErrors;
}
export default mergeErrors;