import isEqual from 'lodash/isEqual';
import {useTranslation} from 'next-i18next';
import React, {useState} from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Collapse, Typography} from '@mui/material';

import {Box} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './styles/stationCard.module.scss';

const RemoteStationInformation = ({variant = 'collapse'}: {variant?: 'collapse' | 'box'}) => {
  const {t} = useTranslation(['common']);
  const [isRemoteStationInfoOpen, setIsRemoteStationInfoOpen] = useState(false);

  return variant === 'collapse' ? (
    <>
      <Collapse in={isRemoteStationInfoOpen} collapsedSize={75}>
        <Box className={styles.remoteStationInfo} p={'10px 20px 0 20px'}>
          <Typography component="p" className={styles.remoteStationInfoHeadline}>
            {t('remote_station_info_headline')}
          </Typography>
          <Typography component="p" className={styles.remoteStationInfoText}>
            {t('remote_station_info')}
          </Typography>
        </Box>
      </Collapse>
      <div
        className={styles.remoteStationInfoButton}
        onClick={() => {
          setIsRemoteStationInfoOpen(!isRemoteStationInfoOpen);
        }}
      >
        {isRemoteStationInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </>
  ) : (
    <>
      <Box className={styles.remoteStationInfo} mb={5} p={3}>
        <Typography component="p" className={styles.remoteStationInfoHeadlineBox}>
          {t('remote_station_info_box_headline')}
        </Typography>
        <Typography
          component="p"
          className={`${styles.remoteStationInfoText} ${styles.remoteStationInfoTextBoxFont}`}
        >
          {t('remote_station_info')}
        </Typography>
      </Box>
    </>
  );
};

export default React.memo(
  withErrorBoundary(RemoteStationInformation, 'RemoteStationInformation'),
  isEqual,
);
