import {signOut, useSession} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import {useEffect, useState} from 'react';

import {Typography} from '@mui/material';

import {getApiV1GetCustomer} from 'lib/api/backend';
import {Customer} from 'lib/api/backend.schemas';

import {Modal, Title} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import DefaultWrapper from 'components/layout/DefaultWrapper';

const UserBlockedModal = () => {
  const {t} = useTranslation('userBlockedModal');
  const [customerData, setCustomerData] = useState<Customer | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const session = useSession();

  const isAuthenticated = session?.status === 'authenticated';

  const accessToken = session?.data?.user?.accessToken;

  useEffect(() => {
    const getCustomerData = async () => {
      if (isAuthenticated) {
        try {
          const response = await getApiV1GetCustomer({
            headers: {
              'X-Auth': `Bearer ${accessToken}`,
            },
          });
          setCustomerData(response);
        } catch {
          signOut({redirect: true});
        }
      }
    };
    getCustomerData();
  }, [isAuthenticated]);

  useEffect(() => {
    try {
      if (isAuthenticated) {
        const hasSeenModal = sessionStorage.getItem('userBlocked');
        if (!hasSeenModal) {
          setOpen(true);
          sessionStorage.setItem('userBlocked', String(true));
        }
      } else {
        sessionStorage.removeItem('userBlocked');
      }
    } catch (err) {
      console.log('sessionStorage probably not available');
    }
  }, [isAuthenticated]);
  try {
    if (isAuthenticated && customerData?.isDisabled && sessionStorage.getItem('userBlocked'))
      return (
        <Modal stickyTop modalOpen={open} hideTrigger>
          <DefaultWrapper bgcolor="yellow" variant="paddingAround">
            <Title variant="h2">{t('hint')}</Title>
            <Typography>{t('accountBlocked')}</Typography>
            <Typography>{t('contactUs')}</Typography>
          </DefaultWrapper>
        </Modal>
      );
  } catch (err) {
    console.log('sessionStorage probably not available');
  }
  return null;
};

export default withErrorBoundary(UserBlockedModal, 'UserBlockedModal');
