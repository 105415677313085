import * as yup from 'yup';

const yupSchema = t => {
  const stationValidation: any = ([stationTextField], schema: any, value: any) => {
    if (!value.length && !stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
    if (stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
  };

  const schema = yup.object({
    station: yup
      .string()
      .required(t('selectStation', {ns: 'bpr'}))
      .when(['stationTextField'] as string[], stationValidation),

    departure: yup
      .date()
      .nullable()
      .required(t('selectRentalStart', {ns: 'bpr'}))
      .test('15-minute-check', t('rentalDateWrongSlot', {ns: 'bpr'}), date => {
        if (date) {
          if (date.getMinutes() % 15 !== 0) {
            return false;
          }
        }
        return true;
      }),
    arrival: yup.date().when(['departure'], dep => {
      return yup
        .date()
        .nullable()
        .required(t('selectRentalEnd', {ns: 'bpr'}))
        .min(new Date(dep), t('rentalDateErrorText', {ns: 'bpr'}))
        .test('15-minute-check', t('rentalDateWrongSlot', {ns: 'bpr'}), date => {
          if (date) {
            if (date.getMinutes() % 15 !== 0) {
              return false;
            }
          }
          return true;
        });
    }),
    distance: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .typeError(t('numberShouldBeNumeric', {ns: 'bpr'}))
      .min(1, t('numberShouldBeAtLeast1', {ns: 'bpr'}))
      .notRequired()
      .nullable(),
  });
  return schema;
};

export default yupSchema;
