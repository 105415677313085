import clsx from 'clsx';

import MuiBox from '@mui/material/Box';

import styles from './scrollBox.module.scss';

const ScrollBox = (props): JSX.Element => {
  return <MuiBox {...props} className={clsx(styles.scrollBar, props.className)} />;
};

export default ScrollBox;
