import Link from 'next/link';
import React from 'react';

import Box from '@mui/material/Box';

import {Icon} from 'components/basic-components/';
import StrapiImage from 'components/basic-components/StrapiImage';

import styles from './Footerslide.module.scss';

const Slide = ({priority, ...props}: any) => {
  const image = props?.image?.data?.attributes;
  const url = `${props?.customURL && props?.customURL.startsWith('/') ? '' : '/'}${
    props?.customURL ? props?.customURL : props?.page?.data?.attributes.fullSlug
  }`;
  return (
    <div className={styles.slide}>
      {props?.customURL || props?.page?.data?.attributes?.fullSlug ? (
        <Link prefetch={false} href={url}>
          <a target={props?.newWindow ? '_blank' : '_self'}>
            {props.label ? (
              <div className={styles.label}>
                <Icon baseClassName="fa-solid" className={`${props.icon} ${styles.icon}`} />
                <span>{props.label}</span>
              </div>
            ) : null}
            <Box className={styles.imageWrapper}>
              <StrapiImage
                priority={priority}
                image={image}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
            </Box>
          </a>
        </Link>
      ) : (
        <>
          <Box className={styles.label}>
            <Icon baseClassName="fa-solid" className={`${props.icon} ${styles.icon}`} />
            {props.label}
          </Box>
          <Box className={styles.imageWrapper}>
            <StrapiImage
              priority={priority}
              image={image}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Slide;
