import {useTranslation} from 'next-i18next';
import {Control, Controller} from 'react-hook-form';

import {FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import {visuallyHidden} from '@mui/utils';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './Styles/formSelectCategory.module.scss';

const CategorySelectField = ({
  control,
  color = 'secondary',
}: {
  control: Control<any, any>;
  color?: 'primary' | 'secondary';
}) => {
  const [t] = useTranslation('bpr');

  return (
    <Controller
      name="category"
      control={control}
      render={({field: props}) => (
        <>
          <FormLabel sx={visuallyHidden} id="choose-pkw-or-lkw">
            {t('vehicleCategory')}
          </FormLabel>
          <RadioGroup
            aria-labelledby="choose-pkw-or-lkw"
            className={`${styles.group} ${styles[`_color-${color}`]}`}
            {...props}
          >
            <div className={styles.wrapper}>
              <FormControlLabel
                className={styles.radio}
                value={t('bestpriceCategoryCar')}
                control={<Radio />}
                label={t('bestpriceCategoryCarLabel')}
              />
            </div>
            <div className={styles.wrapper}>
              <FormControlLabel
                className={styles.radio}
                value={t('bestpriceCategoryTruck')}
                control={<Radio />}
                label={t('bestpriceCategoryTruckLabel')}
              />
            </div>
          </RadioGroup>
        </>
      )}
    />
  );
};

export default withErrorBoundary(CategorySelectField, 'CategorySelectField');
