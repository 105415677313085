import {useTranslation} from 'next-i18next';
import Image from 'next/future/image';
import {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Button, Checkbox, FormControlLabel, Icon, Typography} from '@mui/material';
import {Box} from '@mui/system';

import {GOOGLE_PRIVACY_POLICIES} from 'utils/maps/loadMaps';

import {ConsentContext} from 'components/content-components/ConsentManager';

import Title from '../Title';
import styles from './MapConsentOverlay.module.scss';
import mapConsentBg from './assets/map_consent_bg.jpg';

export const MapsConsentOverlay = ({onSetVisible, height = 420, width = 397}) => {
  const {t} = useTranslation(['bpr', 'common']);

  const {
    state: {maps},
    setConsentStateForKey,
  } = useContext(ConsentContext);
  const {control, handleSubmit} = useForm({
    defaultValues: {mapsAccepted: maps},
  });
  const onButtonClick = handleSubmit(({mapsAccepted}) => {
    if (mapsAccepted) {
      setConsentStateForKey('maps', true);
    } else {
      onSetVisible(true);
    }
  });
  return (
    <Box className={styles.consentOverlay}>
      <Box className={styles.consentOverlay_content}>
        <Title variant="h5" component="p">
          {t('displayMap?')}
        </Title>
        <Typography variant="body2" mt={1}>
          {t('mapsInfoText')}
        </Typography>
        <Typography variant="body2" mt={1}>
          <a href={GOOGLE_PRIVACY_POLICIES} target="_blank" rel="noreferrer">
            {t('learnMore', {ns: 'common'})}
          </a>
        </Typography>
        <Box mt={1}>
          <Button
            onClick={onButtonClick}
            variant="inverted"
            size="small"
            endIcon={<Icon baseClassName="fa-solid" className="fa-chevron-right" />}
          >
            {t('displayMap')}
          </Button>
        </Box>
        <Box>
          <Controller
            name="mapsAccepted"
            control={control}
            render={({field: {name, onChange, value}}) => {
              return (
                <FormControlLabel
                  control={<Checkbox name={name} onChange={onChange} checked={value} />}
                  label={t('alwaysShowMap')}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Image
        className={styles.bg}
        alt=""
        priority
        height={height}
        width={width}
        src={`${mapConsentBg.src}`}
      ></Image>
    </Box>
  );
};

export default MapsConsentOverlay;
