import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import CustomHeader from 'components/content-components/Datepicker/CustomHeader';

import CalendarContainer from './calendar_container';
import * as utils from './date_utils';
import {
  DEFAULT_YEAR_ITEM_NUMBER,
  addDays,
  addMonths,
  addYears,
  addZero,
  formatDate,
  getEffectiveMaxDate,
  getEffectiveMinDate,
  getFormattedWeekdayInLocale,
  getMonth,
  getStartOfToday,
  getStartOfWeek,
  getWeekdayMinInLocale,
  getWeekdayShortInLocale,
  getYear,
  getYearsPeriod,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  monthDisabledAfter,
  monthDisabledBefore,
  newDate,
  setMonth,
  setYear,
  subMonths,
  subYears,
  yearDisabledAfter,
  yearDisabledBefore,
  yearsDisabledAfter,
  yearsDisabledBefore,
} from './date_utils';
import InputTime from './inputTime';
import Month from './month';
import MonthDropdown from './month_dropdown';
import MonthYearDropdown from './month_year_dropdown';
import styles from './stylesheets/datepicker.module.scss';
import Time from './time';
import Year from './year';
import YearDropdown from './year_dropdown';

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
const DROPDOWN_FOCUS_CLASSNAMES = [
  styles['__year-select'],
  styles['__month-select'],
  styles['__month-year-select'],
];

const isDropdownSelect = (element = {}) => {
  const classNames = (element.className || '').split(/\s+/);
  return DROPDOWN_FOCUS_CLASSNAMES.some(testClassname => classNames.indexOf(testClassname) >= 0);
};

const Calendar = props => {
  const {
    onDropdownFocus = () => {},
    monthsShown = 1,
    monthSelectedIn = 0,
    forceShowMonthNavigation = false,
    timeCaption = 'Time',
    previousYearButtonLabel = 'Previous Year',
    nextYearButtonLabel = 'Next Year',
    previousMonthButtonLabel = 'Previous Month',
    nextMonthButtonLabel = 'Next Month',
    customTimeInput = null,
    yearItemNumber = DEFAULT_YEAR_ITEM_NUMBER,
    getRef = () => {},
  } = props;
  const getDateInView = () => {
    const {preSelection, selected, openToDate} = props;
    const minDate = getEffectiveMinDate(props);
    const maxDate = getEffectiveMaxDate(props);
    const current = newDate();
    const initialDate = openToDate || selected || preSelection;
    if (initialDate) {
      return initialDate;
    } else {
      if (minDate && isBefore(current, minDate)) {
        return minDate;
      } else if (maxDate && isAfter(current, maxDate)) {
        return maxDate;
      }
    }
    return current;
  };

  const containerRef = React.useRef();
  useEffect(() => {
    getRef(containerRef?.current);
  }, [containerRef]);
  const monthContainer = React.useRef();
  const [date, setDate] = useState(getDateInView());
  const [selectingDate, setSelectingDate] = useState(null);
  useEffect(() => {
    setDate(prevProps =>
      !isSameDay(props.preSelection, prevProps) ? props.preSelection : prevProps,
    );
  }, [props.preSelection]);
  useEffect(() => {
    setDate(prevProps =>
      !isSameDay(props.openToDate, prevProps.openToDate) ? props.openToDate : prevProps,
    );
  }, [props.openToDate]);

  const handleClickOutside = event => {
    props.onClickOutside(event);
  };

  const setClickOutsideRef = () => {
    return containerRef.current;
  };

  const handleDropdownFocus = event => {
    if (isDropdownSelect(event.target)) {
      onDropdownFocus();
    }
  };

  const increaseMonth = () => {
    setDate(addMonths(date, 1));
  };

  const decreaseMonth = () => {
    setDate(subMonths(date, 1));
  };

  const handleDayClick = (day, event, monthSelectedIn) => {
    props.onSelect(day, event, monthSelectedIn);
    props.setPreSelection && props.setPreSelection(day);
  };

  const handleDayMouseEnter = day => {
    setSelectingDate(day);
    props.onDayMouseEnter && props.onDayMouseEnter(day);
  };

  const handleMonthMouseLeave = () => {
    setSelectingDate(null);
    props.onMonthMouseLeave && props.onMonthMouseLeave();
  };

  const handleYearChange = date => {
    if (props.onYearChange) {
      props.onYearChange(date);
    }
    if (props.adjustDateOnChange) {
      if (props.onSelect) {
        props.onSelect(date);
      }
      if (props.setOpen) {
        props.setOpen(true);
      }
    }

    props.setPreSelection && props.setPreSelection(date);
  };

  const handleMonthChange = date => {
    if (props.onMonthChange) {
      props.onMonthChange(date);
    }
    if (props.adjustDateOnChange) {
      if (props.onSelect) {
        props.onSelect(date);
      }
      if (props.setOpen) {
        props.setOpen(true);
      }
    }

    props.setPreSelection && props.setPreSelection(date);
  };

  const handleMonthYearChange = date => {
    handleYearChange(date);
    handleMonthChange(date);
  };

  const changeYear = React.useCallback(year => {
    setDate(setYear(date, year));
  });

  const changeMonth = month => {
    setDate(setMonth(date, month));
  };
  const changeMonthYear = monthYear => {
    setDate(setYear(setMonth(date, getMonth(monthYear)), getYear(monthYear)));
  };

  const header = (myDate = date) => {
    const startOfWeek = getStartOfWeek(myDate, props.locale, props.calendarStartDay);

    const dayNames = [];
    if (props.showWeekNumbers) {
      dayNames.push(
        <div key="W" className={styles['day-name']}>
          {props.weekLabel || '#'}
        </div>,
      );
    }
    return dayNames.concat(
      [0, 1, 2, 3, 4, 5, 6].map(offset => {
        const day = addDays(startOfWeek, offset);
        const weekDayName = formatWeekday(day, props.locale);

        const weekDayClassName = props.weekDayClassName ? props.weekDayClassName(day) : undefined;

        return (
          <div key={offset} className={classnames(styles['__day-name'], weekDayClassName)}>
            {weekDayName}
          </div>
        );
      }),
    );
  };

  const formatWeekday = (day, locale) => {
    // console.log(day, locale);
    if (props.formatWeekDay) {
      return getFormattedWeekdayInLocale(day, props.formatWeekDay, locale);
    }
    return props.useWeekdaysShort
      ? getWeekdayShortInLocale(day, locale)
      : getWeekdayMinInLocale(day, locale);
  };

  const decreaseYear = () => {
    setDate(subYears(date, props.showYearPicker ? yearItemNumber : 1));
  };
  const increaseYear = () => {
    setDate(addYears(date, props.showYearPicker ? yearItemNumber : 1));
  };
  const prevDateRef = useRef(date);
  useEffect(() => {
    if (getYear(date) !== getYear(prevDateRef.current)) {
      handleYearChange(date);
    }
    if (getMonth(date) !== getMonth(prevDateRef.current)) {
      handleMonthChange(date);
    }
    prevDateRef.current = date;
  }, [date]);
  const renderPreviousButton = () => {
    if (props.renderCustomHeader) {
      return;
    }

    let allPrevDaysDisabled;
    switch (true) {
      case props.showMonthYearPicker:
        allPrevDaysDisabled = yearDisabledBefore(date, props);
        break;
      case props.showYearPicker:
        allPrevDaysDisabled = yearsDisabledBefore(date, props);
        break;
      default:
        allPrevDaysDisabled = monthDisabledBefore(date, props);
        break;
    }

    if (
      (!forceShowMonthNavigation && !props.showDisabledMonthNavigation && allPrevDaysDisabled) ||
      props.showTimeSelectOnly
    ) {
      return;
    }

    const iconClasses = [styles['__navigation-icon'], styles['__navigation-icon--previous']];

    const classes = [styles['__navigation'], styles['__navigation--previous']];

    let clickHandler = decreaseMonth;

    if (props.showMonthYearPicker || props.showQuarterYearPicker || props.showYearPicker) {
      clickHandler = decreaseYear;
    }

    if (allPrevDaysDisabled && props.showDisabledMonthNavigation) {
      classes.push(styles['__navigation--previous--disabled']);
      clickHandler = null;
    }

    const isForYear =
      props.showMonthYearPicker || props.showQuarterYearPicker || props.showYearPicker;

    const {previousMonthButtonLabel, previousYearButtonLabel} = props;

    const {
      previousMonthAriaLabel = typeof previousMonthButtonLabel === 'string'
        ? previousMonthButtonLabel
        : 'Previous Month',
      previousYearAriaLabel = typeof previousYearButtonLabel === 'string'
        ? previousYearButtonLabel
        : 'Previous Year',
    } = props;

    return (
      <button
        type="button"
        className={classes.join(' ')}
        onClick={clickHandler}
        onKeyDown={props.handleOnKeyDown}
        aria-label={isForYear ? previousYearAriaLabel : previousMonthAriaLabel}
      >
        <span className={iconClasses.join(' ')}>
          {isForYear ? previousYearButtonLabel : previousMonthButtonLabel}
        </span>
      </button>
    );
  };

  const renderNextButton = () => {
    if (props.renderCustomHeader) {
      return;
    }

    let allNextDaysDisabled;
    switch (true) {
      case props.showMonthYearPicker:
        allNextDaysDisabled = yearDisabledAfter(date, props);
        break;
      case props.showYearPicker:
        allNextDaysDisabled = yearsDisabledAfter(date, props);
        break;
      default:
        allNextDaysDisabled = monthDisabledAfter(date, props);
        break;
    }

    if (
      (!forceShowMonthNavigation && !props.showDisabledMonthNavigation && allNextDaysDisabled) ||
      props.showTimeSelectOnly
    ) {
      return;
    }

    const classes = [styles['__navigation'], styles['__navigation--next']];
    const iconClasses = [styles['__navigation-icon'], styles['__navigation-icon--next']];
    if (props.showTimeSelect) {
      classes.push(styles['__navigation--next--with-time']);
    }
    if (props.todayButton) {
      classes.push(styles['__navigation--next--with-today-button']);
    }

    let clickHandler = increaseMonth;

    if (props.showMonthYearPicker || props.showQuarterYearPicker || props.showYearPicker) {
      clickHandler = increaseYear;
    }

    if (allNextDaysDisabled && props.showDisabledMonthNavigation) {
      classes.push(styles['__navigation--next--disabled']);
      clickHandler = null;
    }

    const isForYear =
      props.showMonthYearPicker || props.showQuarterYearPicker || props.showYearPicker;

    const {nextMonthButtonLabel, nextYearButtonLabel} = props;
    const {
      nextMonthAriaLabel = typeof nextMonthButtonLabel === 'string'
        ? nextMonthButtonLabel
        : 'Next Month',
      nextYearAriaLabel = typeof nextYearButtonLabel === 'string'
        ? nextYearButtonLabel
        : 'Next Year',
    } = props;

    return (
      <button
        type="button"
        className={classes.join(' ')}
        onClick={clickHandler}
        onKeyDown={props.handleOnKeyDown}
        aria-label={isForYear ? nextYearAriaLabel : nextMonthAriaLabel}
      >
        <span className={iconClasses.join(' ')}>
          {isForYear ? nextYearButtonLabel : nextMonthButtonLabel}
        </span>
      </button>
    );
  };

  const renderCurrentMonth = (myDate = date) => {
    const classes = [styles['__current-month']];

    if (props.showYearDropdown) {
      classes.push(styles['__current-month--hasYearDropdown']);
    }
    if (props.showMonthDropdown) {
      classes.push(styles['__current-month--hasMonthDropdown']);
    }
    if (props.showMonthYearDropdown) {
      classes.push(styles['__current-month--hasMonthYearDropdown']);
    }
    return (
      <div className={classes.join(' ')}>{formatDate(myDate, props.dateFormat, props.locale)}</div>
    );
  };

  const renderYearDropdown = (overrideHide = false) => {
    if (!props.showYearDropdown || overrideHide) {
      return;
    }
    return (
      <YearDropdown
        adjustDateOnChange={props.adjustDateOnChange}
        date={date}
        onSelect={props.onSelect}
        setOpen={props.setOpen}
        dropdownMode={props.dropdownMode}
        onChange={changeYear}
        minDate={props.minDate}
        maxDate={props.maxDate}
        year={getYear(date)}
        scrollableYearDropdown={props.scrollableYearDropdown}
        yearDropdownItemNumber={props.yearDropdownItemNumber}
      />
    );
  };

  const renderMonthDropdown = (overrideHide = false) => {
    if (!props.showMonthDropdown || overrideHide) {
      return;
    }
    return (
      <MonthDropdown
        dropdownMode={props.dropdownMode}
        locale={props.locale}
        onChange={changeMonth}
        month={getMonth(state.date)}
        useShortMonthInDropdown={props.useShortMonthInDropdown}
      />
    );
  };

  const renderMonthYearDropdown = (overrideHide = false) => {
    if (!props.showMonthYearDropdown || overrideHide) {
      return;
    }
    return (
      <MonthYearDropdown
        dropdownMode={props.dropdownMode}
        locale={props.locale}
        dateFormat={props.dateFormat}
        onChange={changeMonthYear}
        minDate={props.minDate}
        maxDate={props.maxDate}
        date={date}
        scrollableMonthYearDropdown={props.scrollableMonthYearDropdown}
      />
    );
  };

  const renderTodayButton = () => {
    if (!props.todayButton || props.showTimeSelectOnly) {
      return;
    }
    return (
      <div className={styles['today-button']} onClick={e => setDate(getStartOfToday())}>
        {props.todayButton}
      </div>
    );
  };

  const renderDefaultHeader = ({monthDate, i}) => (
    <div
      className={`${styles['__header']} ${
        props.showTimeSelect ? styles['__header--has-time-select'] : ''
      }`}
    >
      {renderCurrentMonth(monthDate)}
      <div
        className={`${styles['__header__dropdown']} ${styles[`--${props.dropdownMode}`]}`}
        onFocus={handleDropdownFocus}
      >
        {renderMonthDropdown(i !== 0)}
        {renderMonthYearDropdown(i !== 0)}
        {renderYearDropdown(i !== 0)}
      </div>
      <div className={styles['__day-names']}>{header(monthDate)}</div>
    </div>
  );
  const renderCustomHeader = (headerArgs = {}) => {
    const {monthDate, i} = headerArgs;

    if ((props.showTimeSelect && !monthContainer) || props.showTimeSelectOnly) {
      return null;
    }

    const prevMonthButtonDisabled = monthDisabledBefore(date, props);

    const nextMonthButtonDisabled = monthDisabledAfter(date, props);

    const prevYearButtonDisabled = yearDisabledBefore(date, props);

    const nextYearButtonDisabled = yearDisabledAfter(date, props);

    const showDayNames =
      !props.showMonthYearPicker && !props.showQuarterYearPicker && !props.showYearPicker;
    const monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
      props.useShortMonthInDropdown
        ? M => utils.getMonthShortInLocale(M, props.locale)
        : M => utils.getMonthInLocale(M, props.locale),
    );
    return (
      <div
        className={`${styles['__header']} ${styles['__header--custom']}`}
        onFocus={onDropdownFocus}
      >
        {/* <CustomHeader
          date={date}
          selectingDate={selectingDate}
          monthContainer={monthContainer}
          locale={props.locale}
          monthNames={monthNames}
          customHeaderCount={i}
          monthDate={monthDate}
          changeMonth={changeMonth}
          changeYear={changeYear}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          decreaseYear={decreaseYear}
          increaseYear={increaseYear}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
          prevYearButtonDisabled={prevYearButtonDisabled}
          nextYearButtonDisabled={nextYearButtonDisabled}
        /> */}
        {props.renderCustomHeader({
          date: date,
          selectingDate: selectingDate,
          monthContainer: monthContainer,
          locale: props.locale,
          monthNames,
          customHeaderCount: i,
          monthDate,
          changeMonth: changeMonth,
          changeYear: changeYear,
          decreaseMonth: decreaseMonth,
          increaseMonth: increaseMonth,
          decreaseYear: decreaseYear,
          increaseYear: increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        })}
        {showDayNames && <div className={styles['__day-names']}>{header(monthDate)}</div>}
      </div>
    );
  };

  const renderYearHeader = () => {
    const {showYearPicker, yearItemNumber} = props;
    const {startPeriod, endPeriod} = getYearsPeriod(date, yearItemNumber);
    return (
      <div className={`${styles['__header']} ${styles['__year-header']}`}>
        {showYearPicker ? `${startPeriod} - ${endPeriod}` : getYear(date)}
      </div>
    );
  };

  const renderHeader = headerArgs => {
    switch (true) {
      case props.renderCustomHeader !== undefined:
        return renderCustomHeader(headerArgs);
      case props.showMonthYearPicker || props.showQuarterYearPicker || props.showYearPicker:
        return renderYearHeader(headerArgs);
      default:
        return renderDefaultHeader(headerArgs);
    }
  };

  const renderMonths = () => {
    if (props.showTimeSelectOnly || props.showYearPicker) {
      return;
    }

    var monthList = [];
    var monthsToSubtract = props.showPreviousMonths ? monthsShown - 1 : 0;
    var fromMonthDate = subMonths(date, monthsToSubtract);
    for (var i = 0; i < monthsShown; ++i) {
      var monthsToAdd = i - monthSelectedIn;
      var monthDate = addMonths(fromMonthDate, monthsToAdd);
      var monthKey = `month-${i}`;
      var monthShowsDuplicateDaysEnd = i < monthsShown - 1;
      var monthShowsDuplicateDaysStart = i > 0;
      monthList.push(
        <div key={monthKey} ref={monthContainer} className={styles['__month-container']}>
          {renderHeader({monthDate, i})}
          <Month
            chooseDayAriaLabelPrefix={props.chooseDayAriaLabelPrefix}
            disabledDayAriaLabelPrefix={props.disabledDayAriaLabelPrefix}
            weekAriaLabelPrefix={props.weekAriaLabelPrefix}
            ariaLabelPrefix={props.monthAriaLabelPrefix}
            onChange={changeMonthYear}
            day={monthDate}
            dayClassName={props.dayClassName}
            calendarStartDay={props.calendarStartDay}
            monthClassName={props.monthClassName}
            onDayClick={handleDayClick}
            handleOnKeyDown={props.handleOnDayKeyDown}
            onDayMouseEnter={handleDayMouseEnter}
            onMouseLeave={handleMonthMouseLeave}
            onWeekSelect={props.onWeekSelect}
            orderInDisplay={i}
            formatWeekNumber={props.formatWeekNumber}
            locale={props.locale}
            minDate={props.minDate}
            maxDate={props.maxDate}
            excludeDates={props.excludeDates}
            excludeDateIntervals={props.excludeDateIntervals}
            highlightDates={props.highlightDates}
            selectingDate={selectingDate}
            includeDates={props.includeDates}
            includeDateIntervals={props.includeDateIntervals}
            inline={props.inline}
            shouldFocusDayInline={props.shouldFocusDayInline}
            fixedHeight={props.fixedHeight}
            filterDate={props.filterDate}
            preSelection={props.preSelection}
            setPreSelection={props.setPreSelection}
            selected={props.selected}
            selectsStart={props.selectsStart}
            selectsEnd={props.selectsEnd}
            selectsRange={props.selectsRange}
            selectsDisabledDaysInRange={props.selectsDisabledDaysInRange}
            showWeekNumbers={props.showWeekNumbers}
            startDate={props.startDate}
            endDate={props.endDate}
            peekNextMonth={props.peekNextMonth}
            setOpen={props.setOpen}
            shouldCloseOnSelect={props.shouldCloseOnSelect}
            renderDayContents={props.renderDayContents}
            disabledKeyboardNavigation={props.disabledKeyboardNavigation}
            showMonthYearPicker={props.showMonthYearPicker}
            showFullMonthYearPicker={props.showFullMonthYearPicker}
            showTwoColumnMonthYearPicker={props.showTwoColumnMonthYearPicker}
            showFourColumnMonthYearPicker={props.showFourColumnMonthYearPicker}
            showYearPicker={props.showYearPicker}
            showQuarterYearPicker={props.showQuarterYearPicker}
            isInputFocused={props.isInputFocused}
            containerRef={containerRef}
            monthShowsDuplicateDaysEnd={monthShowsDuplicateDaysEnd}
            monthShowsDuplicateDaysStart={monthShowsDuplicateDaysStart}
          />
        </div>,
      );
    }
    return monthList;
  };

  const renderYears = () => {
    if (props.showTimeSelectOnly) {
      return;
    }
    if (props.showYearPicker) {
      return (
        <div className={styles['__year--container']}>
          {renderHeader()}
          <Year onDayClick={handleDayClick} date={date} {...props} />
        </div>
      );
    }
  };

  const renderTimeSection = () => {
    if (props.showTimeSelect && (monthContainer || props.showTimeSelectOnly)) {
      return (
        <Time
          selected={props.selected}
          openToDate={props.openToDate}
          onChange={props.onTimeChange}
          timeClassName={props.timeClassName}
          format={props.timeFormat}
          includeTimes={props.includeTimes}
          intervals={props.timeIntervals}
          minTime={props.minTime}
          maxTime={props.maxTime}
          excludeTimes={props.excludeTimes}
          filterTime={props.filterTime}
          timeCaption={timeCaption}
          todayButton={props.todayButton}
          showMonthDropdown={props.showMonthDropdown}
          showMonthYearDropdown={props.showMonthYearDropdown}
          showYearDropdown={props.showYearDropdown}
          withPortal={props.withPortal}
          monthRef={monthContainer}
          injectTimes={props.injectTimes}
          locale={props.locale}
          handleOnKeyDown={props.handleOnKeyDown}
          showTimeSelectOnly={props.showTimeSelectOnly}
        />
      );
    }
  };

  const renderInputTimeSection = () => {
    const time = new Date(props.selected);
    const timeValid = isValid(time) && Boolean(props.selected);
    const timeString = timeValid ? `${addZero(time.getHours())}:${addZero(time.getMinutes())}` : '';
    if (props.showTimeInput) {
      return (
        <InputTime
          date={time}
          timeString={timeString}
          timeInputLabel={props.timeInputLabel}
          onChange={props.onTimeChange}
          customTimeInput={customTimeInput}
        />
      );
    }
  };

  const renderChildren = () => {
    if (props.children) {
      return <div className={styles['children-container']}>{props.children}</div>;
    }
  };

  const Container = props.container || CalendarContainer;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onClickOutside);
  return (
    <div ref={wrapperRef}>
      <Container
        className={classnames(styles['datepicker-root'], props.className, {
          [styles['--time-only']]: props.showTimeSelectOnly,
        })}
        showPopperArrow={props.showPopperArrow}
        arrowProps={props.arrowProps}
      >
        {renderPreviousButton()}
        {renderNextButton()}
        {renderMonths()}
        {renderYears()}
        {renderTodayButton()}
        {renderTimeSection()}
        {renderInputTimeSection()}
        {renderChildren()}
      </Container>
    </div>
  );
};
Calendar.propTypes = {
  adjustDateOnChange: PropTypes.bool,
  arrowProps: PropTypes.object,
  chooseDayAriaLabelPrefix: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  container: PropTypes.func,
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dayClassName: PropTypes.func,
  weekDayClassName: PropTypes.func,
  disabledDayAriaLabelPrefix: PropTypes.string,
  monthClassName: PropTypes.func,
  timeClassName: PropTypes.func,
  disabledKeyboardNavigation: PropTypes.bool,
  calendarStartDay: PropTypes.number,
  dropdownMode: PropTypes.oneOf(['scroll', 'select']),
  endDate: PropTypes.instanceOf(Date),
  excludeDates: PropTypes.array,
  excludeDateIntervals: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
  ),
  filterDate: PropTypes.func,
  fixedHeight: PropTypes.bool,
  formatWeekNumber: PropTypes.func,
  highlightDates: PropTypes.instanceOf(Map),
  includeDates: PropTypes.array,
  includeDateIntervals: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
  ),
  includeTimes: PropTypes.array,
  injectTimes: PropTypes.array,
  inline: PropTypes.bool,
  shouldFocusDayInline: PropTypes.bool,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({locale: PropTypes.object})]),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  monthsShown: PropTypes.number,
  monthSelectedIn: PropTypes.number,
  nextMonthAriaLabel: PropTypes.string,
  nextYearAriaLabel: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func,
  onYearChange: PropTypes.func,
  forceShowMonthNavigation: PropTypes.bool,
  onDropdownFocus: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onWeekSelect: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showFullMonthYearPicker: PropTypes.bool,
  showTwoColumnMonthYearPicker: PropTypes.bool,
  showFourColumnMonthYearPicker: PropTypes.bool,
  showYearPicker: PropTypes.bool,
  showQuarterYearPicker: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  onTimeChange: PropTypes.func,
  timeInputLabel: PropTypes.string,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  excludeTimes: PropTypes.array,
  filterTime: PropTypes.func,
  timeCaption: PropTypes.string,
  openToDate: PropTypes.instanceOf(Date),
  peekNextMonth: PropTypes.bool,
  previousMonthAriaLabel: PropTypes.string,
  previousYearAriaLabel: PropTypes.string,
  scrollableYearDropdown: PropTypes.bool,
  scrollableMonthYearDropdown: PropTypes.bool,
  preSelection: PropTypes.instanceOf(Date),
  selected: PropTypes.instanceOf(Date),
  selectsEnd: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectsRange: PropTypes.bool,
  selectsDisabledDaysInRange: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showPreviousMonths: PropTypes.bool,
  showMonthYearDropdown: PropTypes.bool,
  showWeekNumbers: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  todayButton: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  useWeekdaysShort: PropTypes.bool,
  formatWeekDay: PropTypes.func,
  withPortal: PropTypes.bool,
  weekLabel: PropTypes.string,
  yearItemNumber: PropTypes.number,
  yearDropdownItemNumber: PropTypes.number,
  setOpen: PropTypes.func,
  shouldCloseOnSelect: PropTypes.bool,
  useShortMonthInDropdown: PropTypes.bool,
  showDisabledMonthNavigation: PropTypes.bool,
  previousMonthButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nextMonthButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  previousYearButtonLabel: PropTypes.string,
  nextYearButtonLabel: PropTypes.string,
  renderCustomHeader: PropTypes.func,
  renderDayContents: PropTypes.func,
  onDayMouseEnter: PropTypes.func,
  onMonthMouseLeave: PropTypes.func,
  showPopperArrow: PropTypes.bool,
  handleOnKeyDown: PropTypes.func,
  handleOnDayKeyDown: PropTypes.func,
  isInputFocused: PropTypes.bool,
  customTimeInput: PropTypes.element,
  weekAriaLabelPrefix: PropTypes.string,
  monthAriaLabelPrefix: PropTypes.string,
  setPreSelection: PropTypes.func,
};
export default Calendar;
