import {getYear} from 'date-fns';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import React, {useState} from 'react';

import Icon from 'components/basic-components/Icon';

import styles from './index.module.scss';

const years = range(getYear(new Date()), getYear(new Date()) + 10, 1);
const compare = (a, b) =>
  reduce(
    a,
    function (result, value, key) {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    [],
  );
const CustomHeader = props => {
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const {
    date,
    // locale,
    monthNames,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props;

  const toggleMonthDropdown = () => {
    setIsMonthOpen(prevState => !prevState);
    setIsYearOpen(false);
  };
  const toggleYearDropdown = () => {
    setIsYearOpen(prevState => !prevState);
    setIsMonthOpen(false);
  };

  return (
    <div className={styles.headerWrapper}>
      <div
        onClick={() => {
          if (!prevMonthButtonDisabled) {
            decreaseMonth();
          }
        }}
        className={styles.prevMonthButton}
      >
        <Icon baseClassName="fas" className="fa-caret-left"></Icon>
      </div>
      <div className={styles.selectMonthWrapper}>
        <div className={styles.selectMonth} onClick={toggleMonthDropdown}>
          <span>{monthNames[date.getMonth()]}</span>
          <div className={isMonthOpen ? styles.monthDropdown : styles.monthDropdownClosed}>
            {monthNames.map((option, index) => (
              <div className={styles.month} onClick={() => changeMonth(index)} key={option}>
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.selectYearWrapper}>
        <div className={styles.selectYear} onClick={toggleYearDropdown}>
          <span>{date.getFullYear()}</span>
          <div className={isYearOpen ? styles.yearDropdown : styles.yearDropdownClosed}>
            {years.map(option => (
              <div className={styles.year} onClick={() => changeYear(option)} key={option}>
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        onClick={() => {
          if (!nextMonthButtonDisabled) {
            increaseMonth();
          }
        }}
        className={styles.nextMonthButton}
      >
        <Icon baseClassName="fas" className="fa-caret-right"></Icon>
      </div>
    </div>
  );
};
export default React.memo(CustomHeader, (a, b) => {
  const diff = compare(a, b);
  const res = [
    'date',
    'locale',
    'monthNames',
    'changeYear',
    'changeMonth',
    'decreaseMonth',
    'increaseMonth',
    'prevMonthButtonDisabled',
    'nextMonthButtonDisabled',
  ].reduce((acc, prop) => acc || diff.includes(prop), false);
  // return true;
  return !res;
});
