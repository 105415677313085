import {format} from 'date-fns-tz';

import {BestpriceConfigDto, DatepickerData} from 'lib/api/backend.schemas';

import {isSameDay} from 'components/content-components/Datepicker/react-datepicker/src/date_utils';

export interface StationDatepickerData extends DatepickerData {
  partner?: boolean;
}

export const getDatepickerFilterFunctions = (
  datepickerData: StationDatepickerData,
  bestpriceConfig?: BestpriceConfigDto,
  areDependenciesReady = false,
  bookableFrom?: string,
  bookableTo?: string,
) => {
  // Gernerates all Props for the Datepicker from the data from backend
  if (!areDependenciesReady || !datepickerData) return {};
  let {
    holidays,
    allowedTimes,
    disabledWeekdays,
    disabledDates = [],
    closingDate,
  } = datepickerData;
  let minDate = new Date(datepickerData.nextPossibleDeparture);
  if (minDate < new Date()) {
    minDate = new Date();
  }
  if (bookableFrom && minDate < new Date(bookableFrom)) {
    minDate = new Date(bookableFrom);
  }

  // TODO: set Mindate according to opening Times of station if nextPossibleDeparture conflicts with station opening times (-.-)

  // Round the Mindate to the next 15 min interval
  const minHour = minDate.getHours();
  const minMinute = minDate.getMinutes();
  if (minMinute % 15 > 0) {
    const newMinMinute = ((((minMinute + 15) / 15) | 0) * 15) % 60;
    const newMinHour = (((((minMinute / 15) | 0) / 3) | 0) + minHour) % 24;
    minDate.setHours(newMinHour);
    minDate.setMinutes(newMinMinute);
    if (newMinHour < minHour) {
      minDate.setDate(minDate.getDate() + 1);
    }
  }

  let maxDate = (closingDate && new Date(closingDate)) || undefined;
  if ((bookableTo && !maxDate) || (bookableTo && maxDate && maxDate > new Date(bookableTo))) {
    maxDate = new Date(bookableTo);
  }
  const filterOpeningTimes = time => {
    // console.log("FILTER TIME BPR", time, !!datepickerData);
    let minDate = new Date(datepickerData.nextPossibleDeparture);
    if (!datepickerData) return true;
    const dateToCheck = new Date(time);

    const dateToCheckString = format(dateToCheck, 'yyyy-MM-dd');
    const weekday = dateToCheck.getDay();
    const hour = dateToCheck.getHours();
    const minute = dateToCheck.getMinutes();
    let {start, end} = (holidays && holidays[dateToCheckString]) || allowedTimes[weekday];
    if (isSameDay(dateToCheck, minDate)) {
      // if we are on the same day as the minDate use mindate  for start times
      start = format(minDate, 'HH:mm');
    }
    if (isSameDay(dateToCheck, maxDate)) {
      // if we are on the same day as the minDate use mindate  for end times
      end = format(maxDate, 'HH:mm');
    }
    const startTime = parseInt(
      `${start
        .toString()
        .split(':')
        .map(val => (val === '0' ? '00' : val))
        .join('')}`,
      10,
    );
    const endTime = parseInt(
      `${end
        .toString()
        .split(':')
        .map(val => (val === '0' ? '00' : val))
        .join('')}`,
      10,
    );

    return (
      startTime <= parseInt(`${hour}${minute === 0 ? '00' : minute}`, 10) &&
      parseInt(`${hour}${minute === 0 ? '00' : minute}`, 10) <= endTime
    );
  };
  const excludedDates = disabledDates?.map(date => new Date(date + 'T00:00'));

  const filterOpeningDays = date => {
    if (!datepickerData || !disabledWeekdays) return true;
    const weekDay = new Date(date).getDay();

    return !disabledWeekdays.includes(weekDay);
  };

  return {
    filterOpeningTimes,
    filterOpeningDays,
    excludedDates,
    minDate,
    maxDate,
  };
};

export default getDatepickerFilterFunctions;
