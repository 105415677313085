import {ENABLE_SUPERCHAT_MODAL} from 'config/index';
import {useRouter} from 'next/router';
import React, {FC, useEffect, useState} from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import Container from '@mui/material/Container';
import {Box} from '@mui/system';

import FooterSlider from 'components/content-components/FooterSlider';
import UserBlockedModal from 'components/content-components/UserBlockedModal';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {TTranslatedSlugs} from 'components/layout/Navbar/types';
import BPR from 'components/section-components/bpr';

import BackToTopButton from '../BackToTopButton';
import {Footer} from '../Footer';
import Footer2 from '../Footer2';
import Navbar from '../Navbar';

export interface Props {
  translatedSlugs: TTranslatedSlugs | null;
  published?: boolean;
  children?;
}
const MainLayout: FC<Props> = ({children, translatedSlugs, published = true}) => {
  const router = useRouter();
  const {
    locale,
    query: {reservationId, slug},
  } = router;
  const currentPath = router.pathname;
  const regex = /^\/reservation\/\[reservationId]\/step[1-4]|\/stationen\/?$/gi;
  const reservationPath = regex.test(currentPath);
  const [insertSuperchatScript, setInsertSuperchatScript] = useState(false);
  const localeDe = locale.includes('de');
  useEffect(() => {
    const superchatWidget = document.getElementById('superchat-widget');
    if (superchatWidget) superchatWidget.style.display = !localeDe ? 'none' : 'block';
  }, [localeDe]);

  useEffect(() => {
    const removeExistingScript = () => {
      const existingScript = document.getElementById('superchatScript');
      const superchatWidget = document.getElementById('superchat-widget');
      if (superchatWidget) {
        superchatWidget.parentNode.removeChild(superchatWidget);
      }
      if (existingScript) {
        existingScript.remove();
      }
    };

    if (localeDe && insertSuperchatScript) {
      removeExistingScript();

      const script = document.createElement('script');
      script.src = slug?.includes('autoabo')
        ? 'https://widget.superchat.de/snippet.js?applicationKey=WCl0vXzQKwDEnndAEGy7j8amY4'
        : 'https://widget.superchat.de/snippet.js?applicationKey=WCy6dv0z8xbl9ewOBqkAgnVOom';
      script.async = true;
      script.id = 'superchatScript';
      script.referrerPolicy = 'no-referrer-when-downgrade';

      document.body.appendChild(script);
    } else {
      removeExistingScript();
    }

    return () => {
      removeExistingScript();
    };
  }, [localeDe, insertSuperchatScript, slug]);

  useEffect(() => {
    setTimeout(() => {
      if (ENABLE_SUPERCHAT_MODAL) {
        setInsertSuperchatScript(true);
      }
    }, 10000);
  }, []);

  return (
    <>
      <div style={{backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 1000}}>
        <Container maxWidth={false} disableGutters sx={{maxWidth: '1600px'}}>
          <Navbar translatedSlugs={translatedSlugs} />
        </Container>
      </div>

      <Container maxWidth={false} disableGutters sx={{maxWidth: '1600px'}}>
        <BPR withSessionConfig key={(reservationId as string) || 'bpr'} />
        {children}
      </Container>
      <Container maxWidth={false} disableGutters sx={{maxWidth: '1600px'}}>
        {reservationPath ? null : <FooterSlider />}
        <Footer />
        <Footer2 />
      </Container>
      <UserBlockedModal />
      <BackToTopButton />
      {!published ? (
        <Box sx={{bottom: 10, position: 'fixed'}}>
          <div
            style={{
              background: 'white',
              display: 'flex',
              padding: 5,
              width: 'auto',
              border: '1px solid black',
            }}
          >
            <WarningIcon color="warning" />
            Diese Page ist ein Draft
          </div>
        </Box>
      ) : null}
    </>
  );
};

export default withErrorBoundary(MainLayout, 'MainLayout');
