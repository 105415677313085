import {useRouter} from 'next/router';
import React, {useContext} from 'react';

import {Box} from 'components/basic-components';
import Slider from 'components/basic-components/Slider';

import {NavPropsContext} from '../../../context/navPropsContext';
import Slide from './FooterSlide';
import styles from './Footerslider.module.scss';
import { withErrorBoundary } from 'components/error/ErrorBoundary';

// eslint-disable-next-line unused-imports/no-unused-vars
const FooterSlider = () => {
  const router = useRouter();
  const {asPath} = router;
  const onRootPage = asPath === '/';

  const navProps = useContext(NavPropsContext);
  const footerSlider = navProps?.FooterSlider;
  const settings = {
    dots: footerSlider?.dots || true,
    infinite: footerSlider?.infinite || true,
    speed: footerSlider?.speed || 1000,
    autoplay: footerSlider?.autoplay,
    autoplaySpeed: footerSlider?.autoplaySpeed || 5000,
    pauseOnHover: footerSlider?.pauseOnHover || true,
    slidesToShow: footerSlider?.slidesToShow || 1,
    slidesToScroll: footerSlider?.slidesToScroll || 1,
    dotsClass: styles.dots,
  };

  return (
    <Box sx={{marginTop: onRootPage ? 0 : '32px'}}>
      <Slider className={styles.slickWrapper} {...settings}>
        {footerSlider?.slides?.map(data => (
          <Slide key={data.id} {...data} />
        ))}
      </Slider>
    </Box>
  );
};

export default withErrorBoundary(FooterSlider, 'FooterSlider');
