import {NavPropsContext} from 'context/navPropsContext';
import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React, {FC, useContext} from 'react';

import {Box, Grid, Link} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import Menu, {CustomLinkContent} from 'components/layout/Menu';

import styles from './Navbar.module.scss';
import Logo from './assets/logo_starcar2x.png';
import {NavbarProps} from './types';

const Navbar: FC<NavbarProps> = ({translatedSlugs}: NavbarProps) => {
  const {t} = useTranslation('common');
  const navProps = useContext(NavPropsContext);

  const navLinks = navProps?.headerMenuLinks || [];
  return (
    <Box id="headerWrapper" className={styles.wrapper}>
      <Box className={styles.container}>
        <Grid
          alignItems="center"
          container
          direction="row"
          columnSpacing={{xs: 1, sm: 2, md: 4, lg: 8}}
          rowSpacing={8}
        >
          <Grid xs={6} sm={5} md={2.1} lg={2.25} paddingRight={0}>
            <Link href="/">
              <span style={{cursor: 'pointer'}}>
                <Box className={styles.imageWrapper}>
                  <Image priority src={Logo} alt={t('logo')} id="logo" />
                </Box>
              </span>
            </Link>
          </Grid>
          <Grid xs={6} sm={7} md={9.9} lg={9.75} alignItems="center">
            <Box className={styles.navLinks}>
              {navLinks?.map(link => {
                return (
                  link.href && (
                    <Link prefetch={false} passHref key={link.href} href={`${link.href}`}>
                      <CustomLinkContent linkStyleNames={styles.link} link={link} />
                    </Link>
                  )
                );
              })}
            </Box>
            <Box className={styles.menuWrapper}>
              <Menu translatedSlugs={translatedSlugs} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(Navbar, 'Navbar');
