import {useTranslation} from 'next-i18next';
import {Control, Controller} from 'react-hook-form';

import {BaseTextFieldProps, Box} from '@mui/material';

import {TextField} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

const FormInputPreferredVehicle = ({
  control,
  color = 'primary',
}: {
  control: Control<any, any>;
  color?: BaseTextFieldProps['color'];
}) => {
  const [t] = useTranslation('bpr');
  return (
    <Box sx={{width: '100%'}}>
      <Controller
        control={control}
        name="vehicle"
        render={({field: {name, onBlur, onChange, value}}) => {
          return (
            <TextField
              color={color}
              fullWidth
              name={name}
              disabled
              onBlur={onBlur}
              onChange={onChange}
              type="text"
              value={value}
              label={<>{t('desiredVehicle')}</>}
            />
          );
        }}
      />
    </Box>
  );
};
export default withErrorBoundary(FormInputPreferredVehicle, 'FormInputPreferredVehicle');
