import DatePickerFocusContext from 'context/DatePickerFocusContext';
import de from 'date-fns/locale/de';
import React, {useContext, useEffect, useState} from 'react';

import TextField from '@mui/material/TextField';

import Icon from 'components/basic-components/Icon';

import CustomHeader from './CustomHeader';
import styles from './index.module.scss';
import {DatepickerProps} from './index.props';
import ReactDatePicker from './react-datepicker/src';
import {registerLocale} from './react-datepicker/src';
import {addMinutes} from './react-datepicker/src/date_utils';
import { withErrorBoundary } from 'components/error/ErrorBoundary';

// CSS Modules, react-datepicker-cssmodules.css
// import './react-datepicker/dist/react-datepicker.css';

// import './react-datepicker/dist/react-datepicker-cssmodules.css';

// import './react-datepicker/dist/react-datepicker.css';

registerLocale('de', de);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePicker = React.forwardRef(function Datepicker(
  {
    // selectedDateProp = new Date(),
    onChange = () => {},
    filterOpeningTimes,
    filterOpeningDays,
    selectedDate: selectedDateProp,
    controlled = false,
    excludedDates = [],
    disabled = false,
    textFieldProps = {},
    minDate,
    maxDate,
    onFirstOpen = () => {},
    clickId,
  }: DatepickerProps,
  ref,
) {
  // console.log(selectedDateProp)
  if (!controlled && selectedDateProp) {
    console.log('to use `selectedDateProp` for controlled  Datepicker set `controlled` to true');
  }
  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  useEffect(() => {
    if (controlled) {
      setSelectedDate(selectedDateProp);
    }
  }, [selectedDateProp, controlled]);

  // console.log(selectedDate.getHours)
  const {onFocus} = useContext(DatePickerFocusContext);
  const onChangeDatepicker = date => {
    if (controlled) {
      // if switching to a new Date, check if its time is allowed, if not check for Earliest allowed date;
      const findNextPossibleDate = d => {
        if (!filterOpeningTimes(d)) {
          const newDate = addMinutes(d, 15);
          return findNextPossibleDate(newDate);
        } else {
          return d;
        }
      };
      const findInitialNextPossibleDate = d => {
        if (!filterOpeningTimes) {
          return d;
        }
        if (!filterOpeningTimes(d)) {
          d.setHours(0);
          d.setMinutes(0);
          const newDate = addMinutes(d, 15);
          return findNextPossibleDate(newDate);
        } else {
          return d;
        }
      };
      onChange(findInitialNextPossibleDate(date));
    } else {
      onChange(date);
      setSelectedDate(date);
    }
  };
  // Filter Days to be disabled based on disabled_weekdays
  return (
    <div className={`${styles.datepickerWrapper} notranslate`}>
      <ReactDatePicker
        className={styles['datepicker-root']}
        // onCalendarOpen={handleOpenCalendar}
        showPopperArrow={false}
        dateFormatCalendar=" "
        timeCaption={'Zeit'}
        locale="de"
        timeIntervals={15}
        disabled={disabled}
        minDate={minDate}
        filterTime={filterOpeningTimes}
        filterDate={filterOpeningDays}
        showTimeSelect
        excludeDates={excludedDates}
        maxDate={maxDate}
        selected={selectedDate || null}
        onFocus={() => {
          if (!selectedDateProp) {
            onFirstOpen();
          }
          if (onFocus) {
            onFocus(clickId);
          }
        }}
        onInputClick={() => {
          if (!selectedDateProp) {
            onFirstOpen();
          }
        }}
        onChange={onChangeDatepicker}
        todayButton={<Icon baseClassName="fas" className={`fa-home ${styles.todayButton}`}></Icon>}
        dateFormat="dd.MM.yyyy HH:mm"
        customInput={
          <TextField {...textFieldProps} onFocus={() => console.log('focusinput')} inputRef={ref} />
        }
        renderCustomHeader={props => <CustomHeader {...props} />}
      ></ReactDatePicker>
    </div>
  );
});

export default withErrorBoundary(DatePicker, 'DatePicker');
