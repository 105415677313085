import EmergencyContext from 'context/EmergencyContext';
import {addHours, subHours} from 'date-fns';
import {useForm} from 'hooks/custom-react-hook-form';
import usePrevious from 'hooks/usePrevious';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {Typography} from '@mui/material';

import {
  useGetApiV1GetBestpriceConfig,
  useGetApiV1Stations,
  usePostApiV1CreateReservation,
} from 'lib/api/backend';
import {Station} from 'lib/api/backend.schemas';

import {formatDateString} from 'utils/date/formatDate';
import mergeErrors from 'utils/forms/mergeErrors';
import {bestPriceGroupCardFlip} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {Box, FormErrors, Grid} from 'components/basic-components';
import {ConsentContext} from 'components/content-components/ConsentManager';
import {addMinutes} from 'components/content-components/Datepicker/react-datepicker/src/date_utils';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import DefaultWrapper from 'components/layout/DefaultWrapper';

import styles from './BestPriceRechner.module.scss';
import StationSelectionFlyout from './StationSelection/StationSelection';
import {
  FormButtonSubmit,
  FormDatepickerArrival,
  FormDatepickerDeparture,
  FormInputDistance,
  FormInputPreferredVehicle,
  FormSelectCategory,
} from './form-fields';
import getDatepickerFilterFunctions, {StationDatepickerData} from './getDatepickerFilterFunctions';
import yupSchema from './getSchema';
import {TBestPriceRechnerProps} from './types';

const BestPriceRechner = ({
  bookableFrom,
  bookableTo,
  preferredVehicle: vehicle,
  presetStation,
  presetCategory,
  presetGroup,
  presetType,
  presetVip,
  groupData,
  variant = 'header',
  withSessionConfig = false,
  origin = 'bestprice-normal',
}: TBestPriceRechnerProps) => {
  const {t} = useTranslation('bpr');
  const schema = yupSchema(t);
  const {pushDataLayer} = useContext(ConsentContext);

  useEffect(() => {
    if (variant == 'modal' && origin === 'bestprice-group') {
      pushDataLayer(bestPriceGroupCardFlip(groupData, 'tarife', null, vehicle));
    }
  }, []);
  const {
    push,
    query: {reservationId: pathReservationId},
  } = useRouter();

  const {
    isLoading: isStationsLoading,
    isSuccess: isSuccessStations,
    isError,
    error,
    data: stations = [],
  } = useGetApiV1Stations({withDatepickerData: true, groupId: presetGroup || null});

  const {
    isLoading: isBestpriceLoading,
    isSuccess: isSuccessBestprice,
    data: bestpriceConfig,
  } = useGetApiV1GetBestpriceConfig(
    pathReservationId ? {reservationId: pathReservationId as string} : {},
  );

  const {
    isSuccess: isCreateReservationSuccess,
    data: {id: reservationId} = {},
    mutate: createReservationMutate,
    error: createReservationErrors,
  } = usePostApiV1CreateReservation({mutation: {onError: async err => err}});
  let stationsById = {} as {
    [key: string]: Station;
  };
  if (!isStationsLoading && !isError) {
    if (Array.isArray(stations)) {
      stationsById =
        stations?.reduce(
          (acc, station) => {
            acc[station.id] = station;
            return acc;
          },
          {} as {
            [key: string]: Station;
          },
        ) || {};
    } else {
      stationsById = {};
    }
  }

  const requestErrors =
    (createReservationErrors?.response?.data && createReservationErrors?.response?.data?.errors) ||
    {};

  useEffect(() => {
    if (isCreateReservationSuccess && reservationId && !presetGroup) {
      pushDataLayer({
        event: 'bestprice',
        station: stationValue,
        category: categoryValue,
        kilometer: distanceValue,
        affiliation: presetStation ? 'stationsliste' : 'header',
      });
      push(`/reservation/${reservationId}/step1`);
    }
    if (isCreateReservationSuccess && reservationId && presetGroup) {
      pushDataLayer({
        event: 'bestprice',
        station: stationValue,
        category: categoryValue,
        kilometer: distanceValue,
        affiliation: 'fahrzeugliste',
      });
      push(`/reservation/${reservationId}/step2`);
    }
  }, [isCreateReservationSuccess]);

  const {
    clearErrors,
    control,
    formState: {errors: formErrors, isDirty},
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    name: 'BestPriceRechner',
    defaultValues: {
      arrival: null as Date,
      category: (presetCategory || t('bestpriceCategoryCar')) as string,
      departure: null as Date,
      distance: '',
      group: presetGroup || '',
      station: presetStation || '',
      stationTextField: '',
      vehicle,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = async stations => {
    const res = {
      ...stations,
      arrival: `${formatDateString(stations.arrival)}`,
      category: presetCategory || stations.category,
      departure: `${formatDateString(stations.departure)}`,
      distance: stations.distance ? Number(stations.distance) : '',
      group: presetGroup || stations.group,
      type: presetType || null,
      origin: origin,
      vehicle: vehicle || stations.vehicle,
    };
    createReservationMutate({data: res});
  };

  const errors = mergeErrors(formErrors, requestErrors);
  const [
    arrivalValue,
    departureValue,
    stationValue,
    stationTextFieldValue,
    categoryValue,
    distanceValue,
  ] = watch(['arrival', 'departure', 'station', 'stationTextField', 'category', 'distance']);
  const [datepickerData, setDatepickerData] = useState(null as StationDatepickerData);
  const lastStationRef = useRef('');
  const {emergencyOnPointerDownHandler} = useContext(EmergencyContext);

  useEffect(() => {
    if (
      ((isSuccessBestprice && withSessionConfig) || !withSessionConfig) &&
      isSuccessStations &&
      presetStation
    ) {
      setValue('stationTextField', stationsById[presetStation].description);
      setValue('station', presetStation);
    }
  }, [presetStation, isSuccessBestprice, isSuccessStations, withSessionConfig]);

  useEffect(() => {
    if (!stationValue && !!departureValue) {
      setValue('departure', null as Date);
    }
    if (!departureValue && !!arrivalValue) {
      setValue('arrival', null as Date);
    }
  }, [departureValue, arrivalValue]);

  useEffect(() => {
    // change datepicker data according to the selected station and reset arrival and departure
    if (stationValue != lastStationRef.current) {
      setValue('arrival', null as Date);
      setValue('departure', null as Date);
      if (stationValue) {
        const {datepickerData: data = {}, partner} = stationsById?.[stationValue] || {};
        setDatepickerData({...data, partner});
      }
      lastStationRef.current = stationValue;
    }
  }, [stationValue]);
  const previousBestPriceConfigDistance = usePrevious(bestpriceConfig?.distance);
  useEffect(() => {
    if (
      bestpriceConfig &&
      isSuccessBestprice &&
      isSuccessStations &&
      !isDirty &&
      withSessionConfig
    ) {
      const {station, departure, arrival, category, distance} = bestpriceConfig;
      if (station && departure && arrival && category && distance) {
        reset({
          station,
          stationTextField: stationsById[station]?.description,
          departure: new Date(departure),
          arrival: new Date(arrival),
          category,
          distance: `${distance}`,
        });
      }

      // Update departure and arrival seperatly as we have the fields disabled if station and/or departure is empty and the values dont stay
      if (bestpriceConfig.departure) {
        setTimeout(
          () =>
            setValue('departure', new Date(bestpriceConfig.departure), {
              shouldTouch: true,
              shouldDirty: true,
            }),
          20,
        );
      }

      if (bestpriceConfig.arrival) {
        setTimeout(
          () =>
            setValue('arrival', new Date(bestpriceConfig.arrival), {
              shouldTouch: true,
              shouldDirty: true,
            }),
          30,
        );
      }
    }
    if (
      bestpriceConfig?.distance &&
      previousBestPriceConfigDistance &&
      bestpriceConfig?.distance !== previousBestPriceConfigDistance
    ) {
      setTimeout(
        () =>
          setValue('distance', `${bestpriceConfig.distance}`, {
            shouldTouch: true,
            shouldDirty: true,
          }),
        30,
      );
    }
  }, [isSuccessBestprice, bestpriceConfig, isBestpriceLoading, isSuccessStations, isDirty]);

  const {
    excludedDates = [],
    filterOpeningDays = () => true,
    filterOpeningTimes = () => true,
    maxDate = undefined,
    minDate = new Date(),
  } = useMemo(
    () =>
      getDatepickerFilterFunctions(
        datepickerData,
        bestpriceConfig,
        isSuccessStations && isSuccessBestprice,
        bookableFrom,
        bookableTo,
      ),
    [
      datepickerData,
      bestpriceConfig,
      isSuccessStations,
      isSuccessBestprice,
      bookableFrom,
      bookableTo,
    ],
  );
  const onFirstOpenDeparture = () => {
    const findNextPossibleDate = d => {
      if (!filterOpeningTimes(d)) {
        const newDate = addMinutes(d, 15);
        return findNextPossibleDate(newDate);
      } else {
        return d;
      }
    };
    const findInitialNextPossibleDate = d => {
      if (!filterOpeningTimes) {
        return d;
      }
      if (!filterOpeningTimes(d)) {
        d.setHours(0);
        d.setMinutes(0);
        const newDate = addMinutes(d, 15);
        return findNextPossibleDate(newDate);
      } else {
        return d;
      }
    };

    if (stationValue) {
      setValue('departure', findInitialNextPossibleDate(minDate), {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const onFirstOpenArrival = () => {
    if (departureValue) {
      let defaultArrival = addHours(departureValue, 24);
      // special cases if departure is on saturday or sunday
      if ([6, 0].includes(departureValue.getDay())) {
        if (departureValue.getHours() <= 12) {
          defaultArrival.setHours(8, 0);
        } else {
          defaultArrival = subHours(defaultArrival, 1);
        }
      }

      setValue('arrival', defaultArrival, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };
  return (
    <DefaultWrapper
      bgcolor={variant === 'header' ? 'black' : 'white'}
      className={`${styles.wrapper} ${variant === 'header' ? styles.customHeaderErrors : ''}`}
    >
      <form
        onPointerDown={emergencyOnPointerDownHandler}
        onSubmit={handleSubmit(onSubmit)}
        id="form-best-price"
      >
        {presetCategory ? null : (
          <Grid container>
            <Grid xs={12}>
              <FormSelectCategory
                color={variant === 'header' ? 'primary' : 'secondary'}
                control={control}
              />
            </Grid>
          </Grid>
        )}
        <Box className={styles.mainForm}>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent={{sm: 'flex-start', md: 'space-between'}}
            columnSpacing={5}
            rowSpacing={4}
          >
            <Grid
              display="flex"
              flexBasis={
                variant === 'modal'
                  ? {xs: '100%', sm: '100%', md: '26%'}
                  : {xs: '100%', sm: '33.333%', md: '0'}
              }
              flexGrow="1"
              sx={variant === 'modal' ? {maxWidth: {xs: '100%', sm: '100%', md: '32%'}} : {}}
            >
              <Box data-form-id="InputStation" sx={{width: '100%'}}>
                <StationSelectionFlyout
                  control={control}
                  disabled={!!presetStation}
                  errors={errors}
                  setStationTextField={(value: string) => {
                    setValue('stationTextField', value, {shouldDirty: true, shouldTouch: true});
                    clearErrors('station');
                  }}
                  setStation={station => {
                    setValue('station', station?.id || '', {shouldDirty: true, shouldTouch: true});
                    clearErrors('station');
                  }}
                  stations={stations}
                  selectedStation={stationsById?.[stationValue] || null}
                  textFieldColor={variant === 'header' ? 'secondary' : 'primary'}
                  value={stationTextFieldValue}
                  variant={variant}
                />
              </Box>
            </Grid>
            <Grid
              display="flex"
              flexBasis={
                variant === 'modal'
                  ? {xs: '50%', sm: '50%', md: '16%'}
                  : {xs: '50%', sm: '33.333%', md: '0'}
              }
              flexGrow="1"
            >
              <Box data-form-id="InputDatepickerDeparture" sx={{width: '100%'}}>
                <FormDatepickerDeparture
                  color={variant === 'header' ? 'secondary' : 'primary'}
                  control={control}
                  errors={errors}
                  excludedDates={excludedDates}
                  filterOpeningDays={filterOpeningDays}
                  filterOpeningTimes={filterOpeningTimes}
                  maxDate={maxDate}
                  minDate={minDate}
                  onFirstOpenDeparture={onFirstOpenDeparture}
                  stationValue={stationValue}
                />
              </Box>
            </Grid>
            <Grid
              display="flex"
              flexBasis={
                variant === 'modal'
                  ? {xs: '50%', sm: '50%', md: '16%'}
                  : {xs: '50%', sm: '33.333%', md: '0'}
              }
              flexGrow="1"
            >
              <Box data-form-id="InputDatepickerArrival" sx={{width: '100%'}}>
                <FormDatepickerArrival
                  color={variant === 'header' ? 'secondary' : 'primary'}
                  control={control}
                  departureValue={departureValue}
                  errors={errors}
                  maxDate={maxDate}
                  minDate={minDate}
                  onFirstOpenArrival={onFirstOpenArrival}
                />
              </Box>
            </Grid>
            <Grid
              display="flex"
              flexBasis={
                variant === 'modal'
                  ? {xs: '100%', sm: '50%', md: 'calc(26% - (40px + 2rem))'}
                  : {xs: '100%', sm: '33.333%', md: '0'}
              }
              flexGrow="1"
              sx={
                variant === 'modal'
                  ? {maxWidth: {xs: '50%', sm: '50%'}}
                  : {maxWidth: {xs: '50%', sm: '33.333%'}}
              }
            >
              <Box data-form-id="InputDistance" sx={{width: '100%'}}>
                <FormInputDistance
                  color={variant === 'header' ? 'secondary' : 'primary'}
                  control={control}
                  errors={errors}
                />
              </Box>
            </Grid>
            {vehicle && variant === 'modal' ? (
              <>
                <Grid
                  display="flex"
                  flexBasis={{xs: '50%', sm: '50%', md: '26%'}}
                  sx={{maxWidth: {xs: '50%', sm: 'calc(50% - (40px + 2rem))', md: '32%'}}}
                  flexGrow="1"
                >
                  <FormInputPreferredVehicle color={'primary'} control={control} />
                </Grid>
                {presetVip ? (
                  <Grid
                    flexBasis={{xxs: '80%'}}
                    sx={{maxWidth: {xxs: '80%'}, display: {xxs: 'flex', sm: 'none'}}}
                    flexGrow="1"
                  >
                    <Typography>{t('vipGroupNextPossibleDateHint')}</Typography>
                  </Grid>
                ) : null}
              </>
            ) : null}
            <Grid display="flex" flexBasis="0" flexGrow="1" sx={{maxWidth: 'calc(2rem + 40px)'}}>
              <FormButtonSubmit
                opacity={
                  variant === 'header'
                    ? (arrivalValue &&
                        departureValue &&
                        stationValue &&
                        !Object.keys(errors)?.length &&
                        1) ||
                      0.7
                    : variant === 'modal'
                    ? (arrivalValue &&
                        departureValue &&
                        stationValue &&
                        !Object.keys(errors)?.length &&
                        1) ||
                      0.4
                    : 1
                }
              />
            </Grid>
            {presetVip && variant === 'modal' ? (
              <Grid
                flexBasis={{xxs: '100%'}}
                sx={{
                  maxWidth: {xxs: '100%'},
                  display: {xxs: vehicle ? 'none' : 'flex', sm: 'flex'},
                }}
                flexGrow="1"
              >
                <Typography>{t('vipGroupNextPossibleDateHint')}</Typography>
              </Grid>
            ) : null}
          </Grid>
          {createReservationErrors?.response?.status === 429 ? (
            <Box alignContent={'center'} alignItems={'center'}>
              <FormErrors errors={[t('tooManyRequests')]} />
            </Box>
          ) : null}
        </Box>
      </form>
    </DefaultWrapper>
  );
};

export default React.memo(withErrorBoundary(BestPriceRechner, 'BestPriceRechner'));
