import {NavPropsContext} from 'context/navPropsContext';
import SpinnerContext from 'context/spinnerContext';
import {signOut, useSession} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import React, {useContext, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {Box, Link} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import LoginModal from './LoginModal';
import {CustomLinkContent} from './Menu';
import styles from './Menu.module.scss';

const DropDownLogin = () => {
  const {t} = useTranslation('common');
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);
  const {headerMenuLoggedInLinks} = useContext(NavPropsContext);
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => setIsOpen(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const {status} = useSession();
  const session = useSession();

  const isBusinessClient = session?.data?.user?.roles.includes('ROLE_BUSINESS_CLIENT');

  const logout = async () => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      await signOut({redirect: false}).then(() => {
        resolve();
      });
    } catch (err) {
      resolve();
    }
  };

  return status === 'authenticated' ? (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Box className={styles.loggedInMenu}>
        <a onClick={toggleMenu} className={styles.menu}>
          {t('myStarcar')}
        </a>
        <ul
          className={`${styles['flyout-menu']} ${isOpen ? styles.open : styles.closed} ${
            styles.menuLogin
          }`}
        >
          {headerMenuLoggedInLinks
            .filter(object => isBusinessClient || object.businessClientOnly === isBusinessClient)
            .map(
              link =>
                link.href && (
                  <li key={link.id} onClick={link?.title === 'Logout' ? logout : null}>
                    <Link prefetch={false} passHref href={`${link.href}`}>
                      <CustomLinkContent
                        link={link}
                        onClick={() => setIsOpen(false)}
                        styleNames={styles.hideOnXl}
                      />
                    </Link>
                  </li>
                ),
            )}
        </ul>
      </Box>
    </OutsideClickHandler>
  ) : (
    <LoginModal />
  );
};

export default withErrorBoundary(DropDownLogin, 'DropDownLogin');
