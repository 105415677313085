import NextImage, {ImageProps} from 'next/image';
import React, {FC} from 'react';

import {TypoComponentsStarcarBildMitUmlaufendemTextImageDataAttributes} from 'lib/api/strapi';

interface props extends Omit<ImageProps, 'src'> {
  image: TypoComponentsStarcarBildMitUmlaufendemTextImageDataAttributes;
  src?: string;
}
export const Image: FC<props> = ({image, ...nextImageProps}) => {
  return nextImageProps?.layout === 'fill' ? (
    <NextImage
      alt={image?.alternativeText}
      src={image?.url}
      title={image?.caption}
      {...nextImageProps}
    />
  ) : (
    <NextImage
      height={image?.height}
      width={image?.width}
      alt={image?.alternativeText}
      src={image?.url}
      title={image?.caption}
      {...nextImageProps}
    />
  );
};

export default Image;
