import React from 'react';
import PropTypes from 'prop-types';
import styles from './stylesheets/datepicker.module.scss';
import Icon from 'components/basic-components/Icon';
import parentStyles from '../../index.module.scss';
import {
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  newDate,
  getStartOfDay,
  addMinutes,
  formatDate,
  isBefore,
  isEqual,
  isTimeInDisabledRange,
  isTimeDisabled,
  timesToInjectAfter,
  isSameDay,
} from './date_utils';

export default class Time extends React.Component {
  static get defaultProps() {
    return {
      intervals: 30,
      onTimeChange: () => {},
      todayButton: null,
      timeCaption: 'Time',
    };
  }

  static calcCenterPosition = (listHeight, centerLiRef) => {
    return centerLiRef.offsetTop - (listHeight / 2 - centerLiRef.clientHeight / 2);
  };

  static propTypes = {
    format: PropTypes.string,
    includeTimes: PropTypes.array,
    intervals: PropTypes.number,
    selected: PropTypes.instanceOf(Date),
    openToDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    timeClassName: PropTypes.func,
    todayButton: PropTypes.node,
    minTime: PropTypes.instanceOf(Date),
    maxTime: PropTypes.instanceOf(Date),
    excludeTimes: PropTypes.array,
    filterTime: PropTypes.func,
    monthRef: PropTypes.object,
    timeCaption: PropTypes.string,
    injectTimes: PropTypes.array,
    handleOnKeyDown: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({locale: PropTypes.object})]),
    showTimeSelectOnly: PropTypes.bool,
  };

  state = {
    height: null,
  };

  componentDidMount() {
    // code to ensure selected time will always be in focus within time window when it first appears
    this.list.scrollTop = Time.calcCenterPosition(
      this.props.monthRef
        ? this.props.monthRef.clientHeight - this.header.clientHeight
        : this.list.clientHeight,
      this.centerLi,
    );
    this.list.scrollTop = this.centerLi.offsetTop;
    if (this.props.monthRef && this.header) {
      this.setState({
        height: this.props.monthRef.clientHeight - this.header.clientHeight,
      });
    }
  }
  componentDidUpdate(prevProps) {
    // if(isTimeDisabled(this.props.selected, this.props)) {
    //   const {times}  = this.getTimes()
    //   // is in disabled range - select earliest available time
    //   // console.log("is in disabled range", this.props.onChange(times.filter(this.props.filterTime)[0], true))
    //   this.props.onChange(times.filter(this.props.filterTime)[0], true)
    // }
    
    if(!isSameDay(new Date(prevProps.selected), new Date(this.props.selected)) || isTimeDisabled(prevProps.selected, prevProps)) {
      this.list.scrollTop = this.centerLi.offsetTop;
      // console.log(this.list.scrollTop = this.centerLi.offsetTop)
      // console.log("not same day", prevProps.selected, this.props.selected);
    } else {
      // console.log("is same day")
    }
  }
  handleClick = time => {
    if (
      ((this.props.minTime || this.props.maxTime) && isTimeInDisabledRange(time, this.props)) ||
      ((this.props.excludeTimes || this.props.includeTimes || this.props.filterTime) &&
        isTimeDisabled(time, this.props))
    ) {
      return;
    }
    this.props.onChange(time);
  };

  isSelectedTime = (time, currH, currM) =>
    this.props.selected && currH === getHours(time) && currM === getMinutes(time);

  liClasses = (time, currH, currM) => {
    let classes = [
      styles['__time-list-item'],
      this.props.timeClassName ? this.props.timeClassName(time, currH, currM) : undefined,
    ];

    if (this.isSelectedTime(time, currH, currM)) {
      classes.push(styles['__time-list-item--selected']);
    }

    if (
      ((this.props.minTime || this.props.maxTime) && isTimeInDisabledRange(time, this.props)) ||
      ((this.props.excludeTimes || this.props.includeTimes || this.props.filterTime) &&
        isTimeDisabled(time, this.props))
    ) {
      classes.push(styles['__time-list-item--disabled']);
    }
    if (
      this.props.injectTimes &&
      (getHours(time) * 60 + getMinutes(time)) % this.props.intervals !== 0
    ) {
      classes.push(styles['__time-list-item--injected']);
    }

    return classes.join(' ');
  };

  handleOnKeyDown = (event, time) => {
    if (event.key === ' ') {
      event.preventDefault();
      event.key = 'Enter';
    }

    if (event.key === 'Enter') {
      this.handleClick(time);
    }
    this.props.handleOnKeyDown(event);
  };
  getTimes = () => {
    let times = [];
    const format = this.props.format ? this.props.format : 'p';
    const intervals = this.props.intervals;

    const base = getStartOfDay(newDate(this.props.selected));
    const multiplier = 1440 / intervals;
    const sortedInjectTimes =
      this.props.injectTimes &&
      this.props.injectTimes.sort(function (a, b) {
        return a - b;
      });

    const activeDate = this.props.selected || this.props.openToDate || newDate();
    const currH = getHours(activeDate);
    const currM = getMinutes(activeDate);
    const activeTime = setHours(setMinutes(base, currM), currH);

    for (let i = 0; i < multiplier; i++) {
      const currentTime = addMinutes(base, i * intervals);
      times.push(currentTime);

      if (sortedInjectTimes) {
        const timesToInject = timesToInjectAfter(
          base,
          currentTime,
          i,
          intervals,
          sortedInjectTimes,
        );
        times = times.concat(timesToInject);
      }
    }
    return {
      times,
      activeDate,
      currH,
      currM,
      activeTime,
      format
    };
  }

  renderTimes = () => {
    
    const {
      times,
      activeDate,
      currH,
      currM,
      activeTime,
      format
    } = this.getTimes();
    return times.map((time, i) => (
      <li
        key={i}
        onClick={this.handleClick.bind(this, time)}
        className={this.liClasses(time, currH, currM)}
        ref={li => {
          if (isBefore(time, activeTime) || isEqual(time, activeTime)) {
            this.centerLi = li;
          }
        }}
        onKeyDown={ev => {
          this.handleOnKeyDown(ev, time);
        }}
        aria-disabled={isTimeDisabled(time, this.props) ? 'true' : undefined}
        tabIndex="0"
        aria-selected={this.isSelectedTime(time, currH, currM) ? 'true' : undefined}
      >
        {formatDate(time, format, this.props.locale)}
      </li>
    ));
  };
  render() {
    const {height} = this.state;

    return (
      <div
        className={`${styles[`__time-container`]} ${
          this.props.todayButton ? styles['__time-container--with-today-button'] : ''
        }`}
      >
        <div
          className={`${styles[`__header`]} ${styles[`__header--time`]} ${
            this.props.showTimeSelectOnly ? styles['__header--time--only'] : ''
          }`}
          ref={header => {
            this.header = header;
          }}
        >
          <div className={styles['time__header']}>{this.props.timeCaption}</div>
        </div>
        <div className={styles['__time']}>
            <div onClick={() => this.list.scrollTop -= 50} className={parentStyles.prevTimeButton}>
              <Icon baseClassName="fas" className="fa-caret-up"></Icon>
            </div>
          <div className={styles['__time-box']}>
            <div onClick={() => this.list.scrollTop += 50} className={parentStyles.nextTimeButton}>
              <Icon baseClassName="fas" className="fa-caret-down"></Icon>
            </div> 
            <ul
              className={styles['__time-list']}
              ref={list => {
                this.list = list;
              }}
              style={height ? {height} : {}}
              tabIndex="0"
            >
            {this.renderTimes()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
