import {useTranslation} from 'next-i18next';
import {Control, Controller} from 'react-hook-form';

import {BaseTextFieldProps, Box} from '@mui/material';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {FormErrors, Icon, TextField} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './Styles/formInputDistance.module.scss';

const InputDistance = ({
  control,
  errors,
  color = 'primary',
  maxLength,
  regex,
}: {
  control: Control<any, any>;
  errors: TMergedErrors;
  color?: BaseTextFieldProps['color'];
  maxLength?: number;
  regex?: RegExp;
}) => {
  const [t] = useTranslation('bpr');
  return (
    <Box sx={{width: '100%'}}>
      <Controller
        control={control}
        name="distance"
        render={({field: {name, onBlur, onChange, value}}) => {
          const handleChange = e => {
            const regexp = regex || /^[0-9\b]+$/;
            if (
              (e.target.value === '' || regexp.test(e.target.value)) &&
              (!maxLength || e.target.value?.length <= maxLength)
            ) {
              onChange(e.target.value);
            }
          };
          return (
            <TextField
              color={color}
              className={`${styles.numberInput} ${errors?.distance ? 'error' : ''}`}
              fullWidth
              name={name}
              inputProps={{inputMode: 'numeric'}}
              onBlur={onBlur}
              onChange={handleChange}
              value={value}
              label={
                <>
                  <Icon className="fa-tachometer-alt" />
                  {t('plannedKilometers')}
                </>
              }
            />
          );
        }}
      />
      <FormErrors errors={errors.distance} />
    </Box>
  );
};
export default withErrorBoundary(InputDistance, 'InputDistance');
