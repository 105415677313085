import SpinnerContext from 'context/spinnerContext';
import {useForm} from 'hooks/custom-react-hook-form';
import {signIn} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import Link from 'next/link';
import {useContext, useState} from 'react';
import * as yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Icon, TextField, Typography} from '@mui/material';

import mergeErrors from 'utils/forms/mergeErrors';

import {Box, Grid} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import DefaultWrapper from 'components/layout/DefaultWrapper';

import styles from './LoginForm.module.scss';

const LoginForm = ({
  variant,
  onLoginSuccess = () => {},
  outerWrapperClassName = '',
  innerWrapperClassName = '',
  loginButtonPosition = 'flex-end',
  HeadComponents = null,
  closeModalHandler = () => {},
}: {
  variant: 'loginModal' | 'loginPage';
  onLoginSuccess: Function;
  closeModalHandler?: any;
  outerWrapperClassName?: string;
  innerWrapperClassName?: string;
  loginButtonPosition?: 'flex-start' | 'flex-end' | 'center';
  HeadComponents?: React.ReactNode | null;
}) => {
  const {t} = useTranslation(['loginModal', 'password', 'forms', 'common']);
  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .email(t('invalidEmail'))
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    password: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
  });

  const {
    register,
    handleSubmit,
    formState: {errors: formErrors},
  } = useForm({
    name: 'LoginForm',
    defaultValues: {email: '', password: ''},
    resolver: yupResolver(schema),
  });
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);
  const [submitErrors, setSubmitErrors] = useState({email: [''], password: ['']});
  const errors = mergeErrors(formErrors, submitErrors);

  const submitLogin = async ({email, password}) => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      setSubmitErrors({email: [''], password: ['']});
      await signIn('credentials', {username: email, password: password, redirect: false}).then(
        ({ok}) => {
          if (ok) {
            onLoginSuccess();
          } else {
            setSubmitErrors({
              email: [t('pwOrUsernameWrong', {ns: 'password'})],
              password: [t('pwOrUsernameWrong', {ns: 'password'})],
            });
          }
          resolve();
        },
      );
      return;
    } catch (err) {
      resolve();
      setSubmitErrors({
        email: [t('pwOrUsernameWrong', {ns: 'password'})],
        password: [t('pwOrUsernameWrong', {ns: 'password'})],
      });
      return;
    }
  };
  const submitForm = handleSubmit(data => {
    submitLogin(data);
  });
  return (
    <DefaultWrapper
      variant={variant === 'loginModal' ? 'paddingAround' : 'paddingLeftAndRight'}
      className={outerWrapperClassName}
      innerContainerClassName={innerWrapperClassName}
    >
      {HeadComponents}
      <form data-role="login-form" onSubmit={submitForm}>
        <Grid container columnSpacing={8}>
          <Grid xs={12} sm={variant === 'loginModal' ? 12 : 6}>
            <TextField
              className={styles.textField}
              placeholder={t('email', {ns: 'common'})}
              type="email"
              autoComplete="email"
              {...register('email')}
              InputProps={{
                ...register('email'),
              }}
              error={!!errors.email[0]}
              helperText={errors.email[0]}
              label={
                <>
                  <Icon baseClassName="fa-solid" className="fa-user" /> {t('email', {ns: 'common'})}
                </>
              }
            />
          </Grid>
          <Grid xs={12} sm={variant === 'loginModal' ? 12 : 6}>
            <TextField
              className={styles.textField}
              placeholder={t('pw', {ns: 'password'})}
              type="password"
              autoComplete="password"
              InputProps={{
                ...register('password'),
              }}
              {...register('password')}
              error={!!errors.password[0]}
              helperText={errors.password[0]}
              label={
                <>
                  <Icon baseClassName="fa-solid" className="fa-lock" /> {t('pw', {ns: 'password'})}
                </>
              }
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: loginButtonPosition,
            marginTop: variant === 'loginModal' ? 0 : '16px',
          }}
        >
          <Button
            type="submit"
            endIcon={<Icon baseClassName="fa-solid" className="fa-chevron-right" />}
          >
            {t('login')}
          </Button>
        </Box>
      </form>
      <Typography
        variant={variant === 'loginModal' ? 'small2' : 'body1'}
        className={styles.forgotPassword}
      >
        <Link href="/services/passwort-vergessen" passHref>
          <a onClick={closeModalHandler}>{t('forgotPw?', {ns: 'password'})}</a>
        </Link>
      </Typography>
    </DefaultWrapper>
  );
};
export default withErrorBoundary(LoginForm, 'LoginForm');
