import {useTranslation} from 'next-i18next';

import {visuallyHidden} from '@mui/utils';

import {Button, Icon} from 'components/basic-components';

import styles from './Styles/formButtonSubmit.module.scss';

const ButtonSubmit = ({relative = false, opacity = 1}: {relative?: boolean; opacity?: Number}) => {
  const [t] = useTranslation('bpr');
  return (
    <Button
      className={`${styles.submitButton} ${relative ? styles._relative : ''}`}
      endIcon={<Icon baseClassName="fa-solid" className="fa-chevron-right" />}
      style={{opacity: `${opacity}`}}
      type="submit"
      variant="contained"
    >
      <span style={visuallyHidden}>{t('search')}</span>
    </Button>
  );
};
export default ButtonSubmit;
