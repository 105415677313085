import {NavPropsContext} from 'context/navPropsContext';
import SpinnerContext from 'context/spinnerContext';
import {signOut, useSession} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import React, {useContext, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import useMediaQuery from '@mui/material/useMediaQuery';

import {Link} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {CustomLinkContent} from './Menu';
import styles from './Menu.module.scss';

const DropDownMenu = () => {
  const {t} = useTranslation('common');
  const {headerLinks, headerMenuLinks, headerMenuLoggedInLinks} = useContext(NavPropsContext);
  const [isOpen, setIsOpen] = useState(false);
  const session = useSession();
  const closeMenu = () => setIsOpen(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const matches = useMediaQuery('(max-width:885px)');
  const isLoggedIn = session?.data?.user?.accessToken;
  const isBusinessClient = session?.data?.user?.roles.includes('ROLE_BUSINESS_CLIENT');
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);
  const {push} = useRouter();

  const logout = async () => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      await signOut({redirect: false}).then(() => {
        resolve();
        push('/anmeldung');
      });
    } catch (err) {
      resolve();
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <a onClick={toggleMenu} className={styles.menu}>
        <span>{t('menu')}</span>
      </a>
      <ul className={`${styles['flyout-menu']} ${isOpen ? styles.open : styles.closed}`}>
        {matches && isLoggedIn ? (
          <>
            {headerMenuLoggedInLinks
              .filter(object => isBusinessClient || object.businessClientOnly === isBusinessClient)
              .map(
                link =>
                  link.href && (
                    <li
                      key={link.id}
                      className={link.title !== 'Mein Konto' ? styles.accountLink : null}
                      onClick={link?.title === 'Logout' ? logout : null}
                    >
                      <Link passHref prefetch={false} href={`${link.href}`}>
                        <CustomLinkContent
                          link={link}
                          onClick={() => setIsOpen(false)}
                          styleNames={styles.hideOnXl}
                        />
                      </Link>
                    </li>
                  ),
              )}
          </>
        ) : null}
        {matches
          ? headerMenuLinks.map(
              link =>
                link.href && (
                  <li key={link.id}>
                    <Link prefetch={false} passHref href={`${link.href}`}>
                      <CustomLinkContent link={link} onClick={() => setIsOpen(false)} />
                    </Link>
                  </li>
                ),
            )
          : null}

        {headerLinks.map(
          link =>
            link.href && (
              <li key={link.id}>
                <Link passHref href={`${link.href}`}>
                  <CustomLinkContent
                    link={link}
                    onClick={() => setIsOpen(false)}
                    styleNames={styles.hideOnXl}
                  />
                </Link>
              </li>
            ),
        )}
      </ul>
    </OutsideClickHandler>
  );
};

export default withErrorBoundary(DropDownMenu, 'DropDownMenu');
