import {FooterMenuGroup} from 'context/navPropsContext';
import {useTranslation} from 'next-i18next';
import React, {useContext} from 'react';

import {Box} from '@mui/material';

import {Link} from 'components/basic-components';
import {ConsentContext} from 'components/content-components/ConsentManager';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {CustomLinkContent} from 'components/layout/Menu';

import styles from './Footer.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      style={{width: '100%', display: value !== index ? 'none' : undefined}}
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {<>{children}</>}
    </div>
  );
}
const FooterTabPanel = ({
  footerMenuGroups,
  value,
}: {
  footerMenuGroups: FooterMenuGroup[];
  value: number;
}) => {
  const {openModal} = useContext(ConsentContext);
  const {t} = useTranslation('common');
  return (
    <Box sx={{marginTop: '20px', maxWidth: '100%'}}>
      {footerMenuGroups.map((group, index) => {
        return (
          <TabPanel key={`panel-${index}`} value={value} index={index}>
            <ul className={styles.list}>
              {group.menuLinks.map((link, index) => {
                if (
                  link.title.toLowerCase() === 'datenschutz-einstellungen' ||
                  link.title.toLowerCase() === 'data protection settings'
                ) {
                  return (
                    <li key="customDatenschutz">
                      <CustomLinkContent
                        onClick={e => {
                          e.preventDefault();
                          openModal();
                        }}
                        linkStyleNames={styles.links}
                        link={{title: t('dataPrivacySettings')}}
                      />
                    </li>
                  );
                }
                return (
                  <li
                    key={`${link.href}-${index}`}
                    className={`${link.bold ? styles.stationCity : ''} ${
                      link.lineTop ? styles.lineTop : ''
                    }`}
                  >
                    <Link passHref prefetch={false} href={`${link.href}`}>
                      <CustomLinkContent linkStyleNames={styles.links} link={link} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default withErrorBoundary(FooterTabPanel, 'FooterTabPanel');
