import clsx from 'clsx';
import {useTranslation} from 'next-i18next';
import React, {useEffect} from 'react';
import {useRef} from 'react';

import {Box, Theme, Typography, useMediaQuery} from '@mui/material';

import {Grid, ScrollBox, Title} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import StationCard from './StationCard';
import StationGmRadius from './StationGmRadius';
import StationListElement from './StationListElement';
import styles from './styles/stationSelection.module.scss';

function StationSelectWrapper({
  gmRadiusStations,
  setGmRadiusStations,
  setOpen,
  setStation,
  setStationTextField,
  stations,
  value,
  isOpen,
  hoveredStation,
  setHoveredStation,
  valueIsStation,
  filteredStations,
  setFocusOninput,
  variant,
}) {
  const {t} = useTranslation(['bpr', 'common']);
  // hide flyout so images dont get loaded on mobile STARCAR-1057
  const showFlyout = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (!hoveredStation && filteredStations?.length && isOpen) {
      setHoveredStation(filteredStations[0]);
    }
    if (
      hoveredStation &&
      isOpen &&
      filteredStations?.length &&
      !filteredStations.filter(station => hoveredStation.id === station.id).length
    ) {
      setHoveredStation(filteredStations?.[0]);
    }
  }, [filteredStations, hoveredStation, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setHoveredStation(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (hoveredStation) {
      setStation(hoveredStation);
    } else {
      if (!valueIsStation) {
        setStation('');
      }
    }
  }, [hoveredStation]);

  useEffect(() => {
    setGmRadiusStations([]);
  }, [value]);
  const lastSelectedStation = useRef('');

  return (
    <ScrollBox
      className={clsx(
        styles.flyout,
        {[styles.fixed]: variant === 'modal'},
        {[styles.longTermRental]: variant === 'modalLZM'},
      )}
    >
      <Grid
        container
        sx={{marginTop: 0, paddingTop: 0}}
        direction="row"
        alignItems="flex-start"
        id="station-list-overlay"
      >
        <Grid xs={12} md={5} sx={{backgroundColor: '#fff'}}>
          <Box className={styles.wrapper}>
            <Title component="p" variant="h2">
              {t('stations', {ns: 'common'})}
            </Title>
            <ScrollBox component="ul" className={styles.list}>
              {filteredStations?.map(station => {
                return (
                  <StationListElement
                    hoverHandler={setHoveredStation}
                    key={station.id}
                    isActive={station && hoveredStation && station.id === hoveredStation.id}
                    onClick={station => {
                      setStation(station);
                      lastSelectedStation.current = station.description;
                      setStationTextField(station.description);
                      setOpen(false);
                    }}
                    station={station}
                  />
                );
              }) || null}
              {(filteredStations?.length === 0 && (
                <Typography variant="small1" component="li">
                  {t('noStationFound')}
                </Typography>
              )) ||
                null}
            </ScrollBox>
            {!gmRadiusStations?.length ? (
              <StationGmRadius
                setNearbyStations={setGmRadiusStations}
                stations={stations}
                textFieldValue={value}
                setFocusOninput={setFocusOninput}
              />
            ) : null}
          </Box>
        </Grid>
        <Grid xs={12} md={7} sx={{display: {xs: 'none', md: 'block'}, backgroundColor: '#fff'}}>
          {!showFlyout ? null : <StationCard station={hoveredStation} />}
        </Grid>
      </Grid>
    </ScrollBox>
  );
}
export default withErrorBoundary(StationSelectWrapper, 'StationSelectWrapper');
