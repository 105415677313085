import React from 'react';

import LanguageSwitch from 'components/content-components/LanguageSwitch';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import DropDownLogin from './DropDownLogin';
import DropDownMenu from './DropDownMenu';
import styles from './Menu.module.scss';

// provisional Link Component , needed for old layout. Used to accept passHref from next/link so the a tag renders the corrent href for seo reasons
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
export const CustomLinkContent = React.forwardRef<any, any>(function CustomLink(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {href = '', onClick = () => {}, link = {title: '', newWindow: false}, linkStyleNames = ''}: any,
  ref,
) {
  return (
    <a
      className={linkStyleNames}
      href={href}
      onClick={onClick}
      ref={ref}
      target={`${link.newWindow ? '_blank' : '_self'}`}
    >
      {link.title}
    </a>
  );
});
const Menu = ({translatedSlugs}) => {
  return (
    <div className={styles.menuId}>
      <ul className="menu">
        <li>
          <LanguageSwitch translatedSlugs={translatedSlugs} />
        </li>
        <li>
          <DropDownLogin />
        </li>
        <li>
          <DropDownMenu />
        </li>
      </ul>
      <div className={styles.clear}></div>
    </div>
  );
};

export default withErrorBoundary(Menu, 'Menu');
