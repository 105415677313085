import EmergencyContext from 'context/EmergencyContext';
import {useTranslation} from 'next-i18next';
import {useContext} from 'react';
import {Control, Controller} from 'react-hook-form';

import {BaseTextFieldProps, Box} from '@mui/material';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {FormErrors, Icon} from 'components/basic-components';
import DatePicker from 'components/content-components/Datepicker';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

const DepartureDatepicker = ({
  control,
  errors,
  stationValue,
  filterOpeningDays,
  filterOpeningTimes,
  excludedDates,
  onFirstOpenDeparture,
  minDate,
  maxDate,
  color = 'primary',
  clickId,
}: {
  control: Control<any, any>;
  errors: TMergedErrors;
  stationValue: string;
  minDate: Date;
  maxDate: Date;
  excludedDates: Date[];
  onFirstOpenDeparture: Function;
  filterOpeningDays: (time: string | Date) => boolean;
  filterOpeningTimes: (time: string | Date) => boolean;
  color?: BaseTextFieldProps['color'];
  clickId?: Number;
}) => {
  const [t] = useTranslation('bpr');
  const {emergencyOnPointerDownHandler} = useContext(EmergencyContext);

  return (
    <Box sx={{width: '100%'}} onPointerDown={emergencyOnPointerDownHandler}>
      <Controller
        control={control}
        name="departure"
        render={({field: {name, onBlur, onChange, value}}) => {
          return (
            <DatePicker
              controlled={true}
              disabled={!stationValue}
              excludedDates={excludedDates}
              filterOpeningDays={filterOpeningDays}
              filterOpeningTimes={filterOpeningTimes}
              maxDate={maxDate}
              minDate={minDate}
              clickId={clickId}
              onChange={onChange}
              onFirstOpen={onFirstOpenDeparture}
              selectedDate={value}
              textFieldProps={{
                color,
                fullWidth: true,
                inputProps: {
                  readOnly: true,
                  onBlur,
                  name,
                },
                className: errors?.departure ? 'error' : '',
                label: (
                  <>
                    <Icon className="fa-calendar-alt" />
                    {t('rentalStart')}
                  </>
                ),
              }}
            />
          );
        }}
      />
      <FormErrors errors={errors.departure} />
    </Box>
  );
};
export default withErrorBoundary(DepartureDatepicker, 'DepartureDatepicker');
