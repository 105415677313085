import {PlacesServiceContext} from 'context/PlacesServiceContext';
import {useForm} from 'hooks/custom-react-hook-form';
import {useTranslation} from 'next-i18next';
import {useContext, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';

import {Box, Typography} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import getDistanceFromLatLonInKm from 'utils/maps/getDistanceFromLatLonInKm';
import {GOOGLE_PRIVACY_POLICIES} from 'utils/maps/loadMaps';

import {Button, Checkbox, FormControlLabel, Title} from 'components/basic-components';
import {ConsentContext} from 'components/content-components/ConsentManager';

import StationGmRadiusListElement from './StationGmRadiusListElement';
import styles from './styles/stationSelection.module.scss';
import {ICoordinates, IStationWithDistance} from './types';

const StationGmRadius = ({
  setNearbyStations,
  stations,
  textFieldValue,
  setFocusOninput = () => {},
}: {
  setNearbyStations: (stations: IStationWithDistance[]) => void;
  stations: Station[];
  textFieldValue: string;
  setFocusOninput?: Function;
}) => {
  const {t} = useTranslation(['bpr', 'common']);
  const [coordinates, setCoordinates] = useState({} as ICoordinates);
  const [open, setOpen] = useState(false);

  const {getDetails, getPlacePredictions, initialized, init} = useContext(PlacesServiceContext);
  const [placePredictions, setPlacePredictions] = useState([]);

  useEffect(() => {
    if (placePredictions) {
      setOpen(true);
    }
  }, [placePredictions]);

  useEffect(() => {
    setNearbyStations([]);
    setOpen(false);
  }, [textFieldValue]);

  const fetchForInput = () => {
    if (!initialized) {
      init().then(() => {
        setCoordinates({} as ICoordinates);
        getPlacePredictions({input: textFieldValue, componentRestrictions: {country: 'de'}})
          .then(({predictions}) => setPlacePredictions(predictions))
          .catch(() => {});
      });
    } else {
      setCoordinates({} as ICoordinates);
      getPlacePredictions({input: textFieldValue, componentRestrictions: {country: 'de'}})
        .then(({predictions}) => setPlacePredictions(predictions))
        .catch(() => {});
    }
  };

  const getLocationForId = id => {
    setOpen(false);
    setCoordinates({} as ICoordinates);
    getDetails({
      placeId: id,
    })
      .then((location: ICoordinates) => setCoordinates(location))
      .then(() => {
        setFocusOninput();
      });
  };

  useEffect(() => {
    if ((coordinates?.lat, coordinates?.lng)) {
      const filteredStations: IStationWithDistance[] = stations
        .map(({latitude, longitude, ...station}) => {
          return {
            distance: getDistanceFromLatLonInKm(
              latitude,
              longitude,
              coordinates.lat,
              coordinates.lng,
            ),
            latitude,
            longitude,
            ...station,
          };
        })
        .filter(station => station.distance < 100)
        .sort((a, b) => {
          return a.distance - b.distance;
        });
      setNearbyStations(filteredStations);
    } else {
      setNearbyStations([]);
    }
  }, [coordinates]);
  const {
    state: {maps},
    setConsentStateForKey,
  } = useContext(ConsentContext);

  const {control, handleSubmit} = useForm({
    name: 'StationGmRadius',
    defaultValues: {mapsAccepted: maps},
  });

  const onButtonClick = handleSubmit(({mapsAccepted}) => {
    if (mapsAccepted) {
      setConsentStateForKey('maps', true);
    }
    fetchForInput();
  });
  const RadiusDisclaimer = () => {
    if (maps) return null;
    return (
      <>
        <Title variant="h4">{t('searchNearby?')}</Title>
        <Typography component="p" variant="small2" mt={1}>
          {' '}
          {t('mapsUsageInfo')}
        </Typography>
        <Typography component="p" variant="small2" mt={1} mb={4}>
          <a href={GOOGLE_PRIVACY_POLICIES} target="_blank" rel="noreferrer">
            {t('learnMore', {ns: 'common'})}
          </a>
        </Typography>
      </>
    );
  };
  return (
    <Box>
      <RadiusDisclaimer />
      <Box mt={1}>
        <Button onClick={onButtonClick} fullWidth>
          {t('searchNearby')}
        </Button>
        {!maps ? (
          <Controller
            name="mapsAccepted"
            control={control}
            render={({field: {name, onChange, value}}) => {
              return (
                <FormControlLabel
                  control={<Checkbox name={name} onChange={onChange} checked={value} />}
                  label={t('alwaysAllowSearchNearby')}
                />
              );
            }}
          />
        ) : null}
      </Box>
      <Box component="ul" className={styles.list} sx={{display: open ? 'block' : 'none'}}>
        <Title variant="h2" component="p">
          {t('places')}
        </Title>
        {placePredictions.map(pred => (
          <StationGmRadiusListElement
            description={pred.description}
            key={pred.place_id}
            onClick={() => getLocationForId(pred.place_id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StationGmRadius;
