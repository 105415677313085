import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Slider = ({children, ...props}): JSX.Element => {
  const settings = {
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
  };

  return (
    <SlickSlider {...settings} {...props}>
      {children}
    </SlickSlider>
  );
};

export default Slider;
