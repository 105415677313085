import useOutsideAlerter from 'hooks/useOutsideAlerter';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Control} from 'react-hook-form';

import {Box, TextFieldProps} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {FormInputStation} from '../form-fields';
import {TBestPriceRechnerProps} from '../types';
import StationSelectionFlyout from './StationSelectionFlyout';
import {IStationWithDistance} from './types';

const StationSelection = ({
  control,
  disabled,
  errors,
  selectedStation,
  setStation,
  setStationTextField,
  stations = [],
  value = '',
  textFieldColor = 'secondary',
  variant = 'header',
}: {
  control: Control<any, any>;
  disabled?: boolean;
  errors: TMergedErrors;
  selectedStation?: IStationWithDistance;
  setStation: (station: IStationWithDistance) => void;
  setStationTextField: (value: string) => void;
  stations: Station[];
  textFieldColor?: TextFieldProps['color'];
  value: string;
  variant?: TBestPriceRechnerProps['variant'];
}) => {
  const [open, setOpen] = useState(false);
  const [gmRadiusStations, setGmRadiusStations] = useState([] as IStationWithDistance[]);
  const [hoveredStation, setHoveredStation] = useState(null as IStationWithDistance);
  const filterStationsBasedOnInputValue = () => {
    if (!value) return stations;
    const lowerCaseValue = value.toLowerCase();
    return stations?.filter(station => {
      return (
        station.city.toLowerCase().includes(lowerCaseValue) ||
        station.description.toLowerCase().includes(lowerCaseValue) ||
        station.name.toLowerCase().includes(lowerCaseValue) ||
        station.zip.toLowerCase().includes(lowerCaseValue) ||
        ''
      );
    });
  };

  const filteredStations = gmRadiusStations.length
    ? gmRadiusStations
    : filterStationsBasedOnInputValue();

  const [hasFocus, setHasFocus] = useState(false);
  const componentRef = useRef();

  useOutsideAlerter(componentRef, function () {
    setOpen(false);
  });
  useEffect(() => {
    if (hasFocus && value.length) {
      setOpen(true);
    }
    if (hasFocus && !value.length) {
      setOpen(false);
    }
  }, [value, hasFocus]);

  const hoveredStationDown = () => {
    if (!hoveredStation) {
      return;
    }
    filteredStations.find((station, index) => {
      if (hoveredStation.id === station.id) {
        const newIndex = index + 1 >= filteredStations.length ? 0 : index + 1;

        setHoveredStation(filteredStations[newIndex]);
        return true;
      } else {
        return false;
      }
    });
  };
  const hoveredStationUp = () => {
    if (!hoveredStation) {
      return;
    }
    filteredStations.find((station, index) => {
      if (hoveredStation.id === station.id) {
        const newIndex = index - 1 < 0 ? filteredStations.length - 1 : index - 1;
        setHoveredStation(filteredStations[newIndex]);
        return true;
      } else {
        return false;
      }
    });
  };
  const keyDownHandler = useCallback(
    e => {
      switch (e.key) {
        case 'Escape':
          setStationTextField('');
          setStation(null);
          setOpen(false);
          break;
        case 'Enter':
          e.preventDefault();
          e.stopPropagation();
          if (hoveredStation) {
            setStation(hoveredStation);
            setStationTextField(hoveredStation.description);
            setOpen(false);
            setHasFocus(false);
            input.current && input.current?.blur();
          }
          break;
        case 'ArrowDown':
          hoveredStationDown();
          break;
        case 'ArrowUp':
          hoveredStationUp();
          break;
        default:
          break;
      }
    },
    [setStationTextField],
  );

  const input = useRef<HTMLInputElement>();
  const setFocusOninput = () => {
    input.current && input.current?.focus();
  };
  return (
    <Box onKeyDown={keyDownHandler} ref={componentRef} sx={{width: '100%'}}>
      <FormInputStation
        control={control}
        disabled={disabled}
        color={textFieldColor}
        inputRef={input}
        onClear={
          !disabled
            ? () => {
                setStationTextField('');
                setStation(null);
                setOpen(false);
              }
            : null
        }
        onFocus={() => {
          if (!open) {
            // setGmRadiusStations([]);
            setHasFocus(true);
          }
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        errors={errors}
      />
      <Box sx={{display: open ? 'block' : 'none'}}>
        {open ? (
          <StationSelectionFlyout
            gmRadiusStations={gmRadiusStations}
            filteredStations={filteredStations}
            hoveredStation={hoveredStation}
            isOpen={open}
            setFocusOninput={setFocusOninput}
            setGmRadiusStations={setGmRadiusStations}
            setHoveredStation={setHoveredStation}
            setOpen={setOpen}
            setStation={setStation}
            setStationTextField={setStationTextField}
            stations={stations}
            value={value}
            valueIsStation={selectedStation && value === selectedStation.description}
            variant={variant}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default withErrorBoundary(StationSelection, 'StationSelection');
