// @flow
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './stylesheets/datepicker.module.scss';
export default class WeekNumber extends React.Component {
  static propTypes = {
    weekNumber: PropTypes.number.isRequired,
    onClick: PropTypes.func,
  };

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const {weekNumber, ariaLabelPrefix = 'week ', onClick} = this.props;
    const weekNumberClasses = {
      [styles['__week-number']]: true,
      [styles['__week-number--clickable']]: !!onClick,
    };
    return (
      <div
        className={classnames(weekNumberClasses)}
        aria-label={`${ariaLabelPrefix} ${this.props.weekNumber}`}
        onClick={this.handleClick}
      >
        {weekNumber}
      </div>
    );
  }
}
