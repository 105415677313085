import {useTranslation} from 'next-i18next';
import Image from 'next/image';

import {Box, Typography} from '@mui/material';

import {formatDate} from 'utils/date/formatDate';

import {GoogleMapsImage, Grid, Link} from 'components/basic-components';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import RemoteStationInformation from 'components/section-components/bpr/StationSelection/RemoteStationInformation';

import StationInformation from './StationInformation';
import IconAgency from './assets/agency.png';
import IconAirportPickup from './assets/airport_pickup.png';
import IconAirportStation from './assets/airport_station.png';
import IconCentralStationPickup from './assets/central_station_pickup.png';
import IconEcompetence from './assets/ecompetence_icon.png';
import IconLateNight from './assets/late_night.png';
import IconLicensee from './assets/licensee.png';
import IconPartner from './assets/partner.png';
import IconPickupStation from './assets/pickupstation.png';
import IconRemoteStation from './assets/remote_station.png';
import styles from './styles/stationCard.module.scss';
import {IStationWithDistance} from './types';

const StationCard = ({station}: {station: IStationWithDistance}) => {
  const {t} = useTranslation(['bpr', 'common']);
  if (!station) return null;

  const date = new Date();
  let stationOpeningDate = new Date();

  if (station.openingDate) {
    stationOpeningDate = new Date(station.openingDate);
  }

  return (
    <Box className={`stationInformation ${styles.wrapper}`}>
      <Typography component="p" variant="h3" mb={2}>
        {station.description}
      </Typography>

      {station.openingDate && date.getTime() < stationOpeningDate.getTime() && (
        <Link passHref href="/coming-soon">
          <a className={styles.comingSoonLink}>
            <span>
              {t('newFrom', {ns: 'common'})} {formatDate(stationOpeningDate)}
            </span>
          </a>
        </Link>
      )}
      {station.pickupStation && (
        <Box className={styles.pickupStation}>
          <span>{t('onlyPickupStation')}</span>
        </Box>
      )}
      {station.remoteStation && <RemoteStationInformation />}
      {station.closingDate && (
        <Typography component="p" variant="h4" className={styles.headline} mb={1}>
          <span>
            {t('closingInfo', {ns: 'common'})}: {formatDate(station.closingDate)}
          </span>
        </Typography>
      )}
      <Grid container spacing={0} padding={0}>
        <Grid xs={12} lg={6}>
          <StationInformation station={station} />
        </Grid>
        {station.movingDate && (
          <Grid xs={12} lg={6}>
            <Typography
              component="p"
              variant="h4"
              className={styles.headline}
              sx={{color: '#da0000'}}
            >
              <span>
                {t('newAddressFrom')}: {formatDate(station.movingDate)}
              </span>
            </Typography>
            <Box component="address" className={styles.address}>
              {station.movingStreet}
              <br />
              {station.movingZip} {station.movingCity}
            </Box>
          </Grid>
        )}
        {station.remoteStation && (
          <Grid xs={12} lg={6}>
            <StationInformation station={station.relatedStation} />
          </Grid>
        )}
      </Grid>

      {station.airportPickupService && (
        <Box className={styles.patch}>
          <Image src={IconAirportPickup} alt={t('airportPickup')} width="40px" height="40px" />{' '}
          <span>{t('airportPickup')}</span>
        </Box>
      )}
      {station.pickupStation && (
        <Box className={styles.patch}>
          <Image src={IconPickupStation} alt={t('pickupStation')} width="40px" height="40px" />
          <span>{t('pickupStation')}</span>
        </Box>
      )}
      {station?.airportStation && (
        <Box className={styles.patch}>
          <Image src={IconAirportStation} alt={t('airportStation')} width="40px" height="40px" />{' '}
          <span>{t('airportStation')}</span>
        </Box>
      )}
      {station?.remoteStation && (
        <Box className={styles.patch}>
          <Image src={IconRemoteStation} alt={t('remoteStation')} width="40px" height="40px" />{' '}
          <span>{t('remoteStation')}</span>
        </Box>
      )}
      {station.partner && (
        <Box className={styles.patch}>
          <Image
            src={IconPartner}
            alt={t('starcarPartner', {ns: 'common'})}
            width="60px"
            height="60px"
          />{' '}
          <span>{t('starcarPartner', {ns: 'common'})}</span>
        </Box>
      )}
      {station.trainPickupService && (
        <Box className={styles.patch}>
          <Image
            src={IconCentralStationPickup}
            alt={t('centralStationPickup')}
            width="40px"
            height="40px"
          />
          <span>{t('centralStationPickup')}</span>
        </Box>
      )}
      {station.lateNightService && (
        <Box className={styles.patch}>
          <Image src={IconLateNight} alt={t('lateNightStation')} width="40px" height="40px" />
          <span>{t('lateNightStation')}</span>
        </Box>
      )}
      {station.ecompetence && (
        <Box className={styles.patch}>
          <Image src={IconEcompetence} alt={t('eCompetenceService')} width="40px" height="40px" />
          <span>{t('eCompetenceService')}</span>
        </Box>
      )}
      {station.agency && (
        <Box className={styles.patch}>
          <Image src={IconAgency} alt={t('agency')} width="60px" height="60px" />
          <span>{t('agency')}</span>
        </Box>
      )}
      {station.licensee && (
        <Box className={styles.patch}>
          <Image
            src={IconLicensee}
            alt={t('licenseHolder', {ns: 'common'})}
            width="60px"
            height="60px"
          />
          <span>{t('licenseHolder', {ns: 'common'})}</span>
        </Box>
      )}

      <Box className={styles.map}>
        <GoogleMapsImage
          key={`${station.id}`}
          alt={t('stationMap')}
          height={397}
          width={511}
          markers={`${
            station.partner || station.remoteStation
              ? 'https://minio.starcar.de/strapi/icon_map_station_partner2x_tiny_973deb300d.png'
              : 'https://minio.starcar.de/strapi/icon_map_station2x_tiny_53d9063ef0.png'
          }`}
          lat={station.latitude}
          lng={station.longitude}
          secondMarkers={
            station.remoteStation
              ? 'https://minio.starcar.de/strapi/icon_map_station2x_tiny_53d9063ef0.png'
              : ''
          }
          secondLat={station.relatedStation?.latitude}
          secondLng={station.relatedStation?.longitude}
          polyline={station.relatedStationPolyline}
        />
      </Box>
    </Box>
  );
};

export default withErrorBoundary(StationCard, 'StationCard');
